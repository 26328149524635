import { _Get } from "./base";
import Cookies from "js-cookie";

const endpoint = "mtm/inventory";

const pdcSearch = (pageIndex, pageSize, category) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/pdc/${category}`, {
    params: {
      pageIndex,
      pageSize,
    },
    ..._options,
  });
};

const supplierSearch = (pageIndex, pageSize, date, category) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(
    `${process.env.REACT_APP_API_URL}/${endpoint}/supplier/${category}`,
    {
      params: {
        pageIndex,
        pageSize,
        date,
      },
      ..._options,
    }
  );
};

const detailSearch = (pageIndex, pageSize, uuid, date, category) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(
    `${process.env.REACT_APP_API_URL}/${endpoint}/detail/${category}`,
    {
      params: {
        pageIndex,
        pageSize,
        date,
        uuid,
      },
      ..._options,
    }
  );
};

const inventoryReport = (category) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(
    `${process.env.REACT_APP_API_URL}/${endpoint}/report/${category}`,
    _options
  );
};

export { pdcSearch, supplierSearch, detailSearch, inventoryReport };
