import React, { useEffect, useState } from "react";
import CuttingModel from "../../API/CSMCutting";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { DataTable, Input } from "../../Components";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import CSMVendor from "../../API/CSMvendor";
import CSMReceiving from "../../API/CSMReceiving";
import { useModal } from "../../Hook/useModal";
import { Modal } from "react-bootstrap";
import { Action } from "../../Constant";
import { ConfirmationModal } from "../../Components";
import fileDownload from "js-file-download";

export default function Detail({ selectedPT, setSelectedPT }) {
  const _cutting = new CuttingModel();
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const [action, setAction] = useState(Action.CREATE);
  const [selected, setSelected] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [lastModificationTimestamp, setLastDataModificationTimestamp] =
    useState();
  const { modal, toggleModal } = useModal();
  const confirmationModal = useModal();

  const _CSMReceiving = new CSMReceiving();
  const _CSMVendor = new CSMVendor();

  const handleDelete = () => {
    _cutting
      .DeleteNaturalLoin(selected.uuid)
      .then((response) => {
        setToastInfo({
          message: "Natural Loin successfully created",
          background: "success",
        });
        setIsShowToast(true);
        setLastDataModificationTimestamp(new Date());
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "Natural Loin failed created",
          background: "danger",
        });
      });
  };

  const formik = useFormik({
    initialValues: {
      receiving: "",
      cuttingDate: moment(new Date()).format("YYYY-MM-DD"),
      weight: "",
    },
    validationSchema: Yup.object({
      receiving: Yup.object().required("receiving date is required"),
      cuttingDate: Yup.string().required("date is required"),
      weight: Yup.number().required("weight is required"),
    }),
    onSubmit: (values) => {
      const data = {
        weight: values.weight,
        receivingUuid: values.receiving.value,
        cuttingDate: values.cuttingDate,
        cuttingUuid: selectedPT.uuid,
      };
      if (action === Action.CREATE) {
        _cutting
          .CreateNaturalLoin(data)
          .then((response) => {
            setToastInfo({
              message: "Natural Loin successfully created",
              background: "success",
            });
            setIsShowToast(true);
            setLastDataModificationTimestamp(new Date());
            formik.setFieldValue("weight", "");
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Natural Loin failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        _cutting
          .UpdateNaturalLoin(selected.uuid, data)
          .then((response) => {
            setToastInfo({
              message: "Natural Loin successfully updated",
              background: "success",
            });
            setIsShowToast(true);
            toggleModal();
            setLastDataModificationTimestamp(new Date());
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Retouching failed updated",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  const exportReport = (uuid, date) => {
    _cutting
      .ExportNaturalLoint(uuid, date)
      .then((response) => {
        const fileName = `PT - ${selectedPT.pt} - ${date}.csv`;
        fileDownload(response.data, fileName);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "data not found",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const tableHeader = [
    {
      name: "Cutting Date",
    },
    {
      name: "Total Count",
    },
    {
      name: "Total Weight",
    },
    {
      name: "Action",
    },
  ];

  const tableBody = [
    {
      name: "cuttingDate",
      view: (data) => {
        return (
          <a
            href=""
            onClick={(e) => {
              e.preventDefault();
              setSelectedDate(moment(data.cuttingDate).format("YYYY-MM-DD"));
            }}
          >
            {moment(data.cuttingDate).format("DD-MM-YYYY")}
          </a>
        );
      },
    },
    {
      name: "totalCount",
    },
    {
      view: (data) => parseFloat(data.totalWeight).toFixed(2),
    },
    {
      view: (data) => {
        return (
          <button
            className="btn btn-info py-0 px-1 text-white"
            onClick={() => {
              // setSelectedDate(moment(data.cuttingDate).format("YYYY-MM-DD"));
              exportReport(
                selectedPT.uuid,
                moment(data.cuttingDate).format("YYYY-MM-DD")
              );
            }}
          >
            <i className="bi bi-download"></i>
          </button>
        );
      },
    },
  ];

  const tableHeaderIndo = [
    {
      name: "Lot",
    },
    {
      name: "PDC",
    },
    {
      name: "Origin",
    },
    {
      name: "Weight",
    },
    {
      name: "Action",
    },
  ];

  const tableBodyIndo = [
    {
      name: "lot",
      view: (data) => {
        return data.reff + moment(data.receivingDate).format("DDMMYY");
      },
    },
    {
      name: "pdc",
      view: (data) => {
        return moment(data.receivingDate).format("DD-MM-YYYY");
      },
    },
    {
      name: "origin",
    },
    {
      name: "weight",
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                setAction(Action.EDIT);
                toggleModal();
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1"
              onClick={() => {
                setSelected(data);
                confirmationModal.toggleModal();
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (selected?.uuid) {
      _cutting.ReadNaturalLoin(selected.uuid).then((response) => {
        const data = {
          cuttingDate: moment(response.data.cuttingDate).format("YYYY-MM-DD"),
          weight: response.data.weight,
          vendor: {
            value: response.data.vendorUuid,
            label: response.data.rmLotCode,
          },
          receiving: {
            value: response.data.receivingUuid,
            label:
              response.data.reff +
              moment(response.data.receivingDate).format("DDMMYY"),
          },
        };

        formik.setValues(data);
      });
    }
  }, [selected]);

  if (!selectedPT) {
    return null;
  }

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">
              Detail {selectedPT.pt} - Natural Loin
            </h5>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedPT();
                }}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          </div>

          <div className="d-flex justify-content-end">
            <div className="d-flex align-items-center">
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  formik.resetForm();
                  toggleModal();
                }}
              >
                Create
              </button>
            </div>
          </div>
          <DataTable
            api={_cutting.NaturalLoinListDate}
            params={{ cuttingUuid: selectedPT.uuid }}
            tableHeader={
              selectedPT?.vendor === "ID" ? tableHeaderIndo : tableHeader
            }
            tableBody={selectedPT?.vendor === "ID" ? tableBodyIndo : tableBody}
            dependencies={[lastModificationTimestamp, selectedPT]}
            activeClassName={(item) => {
              if (
                moment(item?.cuttingDate).format("YYYY-MM-DD") === selectedDate
              ) {
                return "active";
              }
            }}
          />
        </div>
      </div>
      {selectedDate && (
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5 className="card-title">
                Detail {selectedPT.pt} / {selectedDate} / Natural Loin
              </h5>
              <div className="d-flex align-items-center">
                <button
                  className="btn btn-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    setSelectedDate();
                  }}
                >
                  <i className="bi bi-x"></i>
                </button>
              </div>
            </div>

            <DataTable
              api={_cutting.NaturalLoinListDetail}
              params={{
                cuttingDate: selectedDate,
                cuttingUuid: selectedPT.uuid,
              }}
              tableHeader={tableHeaderIndo}
              tableBody={tableBodyIndo}
              dependencies={[
                lastModificationTimestamp,
                selectedDate,
                selectedPT,
              ]}
            />
          </div>
        </div>
      )}

      <Modal show={modal} onHide={toggleModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Natural Loin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Input
              label="RM Lot Code"
              type="select-api"
              name="vendor"
              onChange={(e) => {
                if (formik.values.receiving) {
                  formik.setFieldValue("receiving", "");
                }

                formik.setFieldValue("vendor", e.target.value);
              }}
              value={formik.values.vendor}
              errorMessage={formik.errors?.vendor}
              isError={formik.errors.vendor && formik.touched.vendor}
              disabled={action === Action.VIEW && true}
              api={_CSMVendor.PagedSearchVendor}
              handleSetOptions={(pt) => ({
                value: pt.uuid,
                label: pt.rmLotCode,
                reff: pt.reff,
              })}
            />
            <Input
              label="CSM PDC Lot"
              type="select-api"
              name="receiving"
              value={formik.values.receiving}
              onChange={formik.handleChange}
              errorMessage={formik.errors?.receiving}
              isError={formik.errors.receiving && formik.touched.receiving}
              disabled={action === Action.VIEW && true}
              api={_CSMReceiving.ReadPDCByVendorUuid}
              additionalParams={{ uuid: formik.values?.vendor?.value }}
              handleSetOptions={(pt) => ({
                value: pt.uuid,
                label: pt.reff + pt.pdc,
              })}
            />
            <Input
              label="Cutting Date"
              type="date"
              name="cuttingDate"
              value={formik.values.cuttingDate}
              onChange={formik.handleChange}
              errorMessage={formik.errors?.cuttingDate}
              isError={formik.errors.cuttingDate && formik.touched.cuttingDate}
              // disabled={action === Action.VIEW && true}
            />
            <Input
              label="Weight"
              type="number"
              name="weight"
              value={formik.values.weight}
              onChange={formik.handleChange}
              errorMessage={formik.errors?.weight}
              isError={formik.errors.weight && formik.touched.weight}
              onKeyDown={(e) => e.key === "Enter" && formik.handleSubmit()}
              // disabled={action === Action.VIEW && true}
            />
          </>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={toggleModal}>
            Close
          </button>
          <button className="btn btn-primary" onClick={formik.handleSubmit}>
            Save
          </button>
        </Modal.Footer>
      </Modal>
      <ConfirmationModal
        isOpen={confirmationModal.modal}
        toggle={confirmationModal.toggleModal}
        message={`are you sure want to delete?`}
        onSubmit={handleDelete}
      />
    </div>
  );
}
