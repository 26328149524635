import React, { useState } from "react";
import CSMRepack from "../../API/CSMRepack";
import { DataTable } from "../../Components";
import fileDownload from "js-file-download";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import moment from "moment";
import { useModal } from "../../Hook/useModal";
import Modal from "./Modal";

export default function Daily({ setSelectedDate, selectedDate }) {
  const _csmrepack = new CSMRepack();
  const { lastDataModificationTimestamp } = useApplicationStoreContext();
  const formattedDate = moment(new Date()).format("YYYY-MM-DD");
  const [refresh, setRefresh] = useState(false);
  const [date, setDate] = useState({
    start: formattedDate,
    end: "",
  });
  const { modal, toggleModal } = useModal();

  const handleExportDaily = () => {
    _csmrepack
      .ExportRepack(date)
      .then((response) => {
        const fileName = `Repack (${date.start} - ${date.end}).csv`;
        fileDownload(response.data, fileName);
      })
      .catch((err) => {});
  };

  const tableHeader = [
    {
      name: "Repack Date",
    },
  ];

  const tableBody = [
    {
      name: "year",
      view: (data) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedDate(data.repackDate);
            }}
          >
            {moment(data.repackDate).format("DD-MM-YYYY")}
          </a>
        );
      },
    },
  ];

  return (
    <div className={`col-lg-12`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">Repack</h5>
            <div className="align-items-center d-flex justify-content-between">
              <div className="d-flex">
                <input
                  type="date"
                  className="form-control me-1"
                  value={date.start}
                  onChange={(e) => setDate({ ...date, start: e.target.value })}
                />
                <input
                  type="date"
                  className="form-control me-1"
                  value={date.end}
                  onChange={(e) => setDate({ ...date, end: e.target.value })}
                />
                <button
                  className="btn btn-primary me-1"
                  onClick={handleExportDaily}
                >
                  Download
                </button>
                <button className="btn btn-primary me-1" onClick={toggleModal}>
                  Create
                </button>
              </div>
            </div>
          </div>

          <DataTable
            api={_csmrepack.GroupByRepackDate}
            pageSize={10}
            tableHeader={tableHeader}
            tableBody={tableBody}
            dependencies={[lastDataModificationTimestamp, refresh]}
            params={{ keyword: "" }}
            activeClassName={(item) => {
              if (item?.repackDate === selectedDate) {
                return "active";
              }
            }}
          />
        </div>
      </div>
      <Modal show={modal} toggleModal={toggleModal} setRefresh={setRefresh} />
    </div>
  );
}
