import React, { useState, useEffect, useRef } from "react";
import {
  CreateNewRetouching,
  UpdateRetouching,
  DeleteRetouching,
  PagedSearchRetouching,
  ExportDailyRetouching,
} from "../../API";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { Form } from "react-bootstrap";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { Loading } from "../../Components";
import fileDownload from "js-file-download";

export default function RetouchingDetail() {
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  const formattedDate = yyyy + "-" + mm + "-" + dd;

  const [keyword, setKeyword] = useState("");
  const [retouching, setRetouching] = useState();
  const [selectedReceiving, setSelectedReceiving] = useState();
  const [selectedTrimming, setSelectedTrimming] = useState();
  const [isLoading, setIsLoading] = useState();
  const [vendor, setVendor] = useState();
  const [date, setDate] = useState({
    start: formattedDate,
    end: null,
  });
  const [isSaving, setIsSaving] = useState(false);
  const ref = useRef();

  const getRetouching = () => {
    setIsLoading(true);
    setRetouching();
    setSelectedReceiving();
    setSelectedTrimming();
    PagedSearchRetouching(keyword)
      .then((response) => {
        ref.current.value = "";
        setRetouching([
          ...response.data.data,
          {
            retouchingDate: formattedDate,
            trimmingUuid: response.data.trimming.uuid,
            receivingUuid: response.data.trimming.receivingUuid,
            vendorUuid: response.data.trimming.vendorUuid,
            grade: "AAA",
            cuttingType: "A",
          },
        ]);
        setSelectedReceiving(response.data.receiving);
        setSelectedTrimming(response.data.trimming);
        setVendor(response.data.vendor);
        setIsLoading(false);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "Retouching code not found",
          background: "danger",
        });
        setIsShowToast(true);
        setIsLoading(false);
        setSelectedReceiving();
        setSelectedTrimming();
      });
  };

  useEffect(() => {
    if (keyword.length === 18) {
      getRetouching();
    }
  }, [keyword]);

  const exportPDF = (data) => {
    const sizeConverter = (size) => {
      switch (size) {
        case 1:
          return "1-3 Lbs";
        case 3:
          return "3-5 Lbs";
        default:
          return "5 Lbs Up";
      }
    };

    const cuttingTypeConverter = (type) => {
      switch (type) {
        case "A":
          return "FULL";
        case "B":
          return "HEAD";
        default:
          return "TAIL";
      }
    };

    var doc = new jsPDF("l", "mm", [100, 50]);

    QRCode.toDataURL(`${keyword}.${data.cuttingType}`, { margin: 1 })
      .then((url) => {
        const fontSize = 7;
        const fontFamily = "helvetica";
        const fontWeight = "bold";

        doc.setFont(fontFamily, fontWeight);
        doc.setFontSize(fontSize);
        doc.text(vendor.fleet, 5, 5);
        doc.text(
          `${cuttingTypeConverter(data.cuttingType)} ${data.grade}`,
          5,
          10
        );

        doc.setFont(fontFamily, fontWeight);
        doc.setFontSize(fontSize);
        doc.text("FROZEN YELLOWFIN TUNA LOIN", 30, 5);
        doc.text("Species", 30, 10);
        doc.text("YFT", 30, 15);
        doc.text("Internal Lot Code", 30, 20);
        doc.text("Origin", 30, 30);
        doc.text("Indonesia", 30, 34);
        doc.text(`Certificate: ${vendor.certificateType}`, 30, 38);
        doc.text("Keep Frozen -18°C", 30, 42);
        doc.text("Allergen Statement Contains Fish (Tuna)", 30, 46);
        doc.text("Fishing Ground", 45, 30);
        doc.text("715", 45, 34);
        doc.text("Grade", 53, 10);
        doc.text(
          `${cuttingTypeConverter(data.cuttingType)} ${data.grade}`,
          53,
          15
        );
        doc.text("Weight (Kg)", 75, 10);
        doc.text("Remarks", 75, 20);
        doc.text(data.weight, 75, 15);
        doc.text(sizeConverter(data.size), 5, 15);
        doc.text(selectedTrimming.trimmingDate, 8, 41);

        doc.setFontSize(15);
        doc.text(selectedTrimming.remarks || selectedReceiving.remarks, 75, 30);

        doc.setFontSize(10);
        doc.text(`${keyword}.${data.cuttingType}`, 30, 25);

        doc.text(`${vendor.certificateType} ${selectedReceiving.owner}`, 5, 46);

        // source, format, x, y ,width, height
        doc.addImage(url, "JPEG", 5, 17, 20, 20);

        doc.autoPrint();
        var iframe = document.createElement("iframe");
        iframe.src = doc.output("datauristring");
        iframe.style.width = "0";
        iframe.style.height = "0";
        document.body.appendChild(iframe);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSubmit = (item, index) => {
    setIsSaving(true);
    if (!item.uuid) {
      CreateNewRetouching({
        ...item,
      })
        .then((res) => {
          setIsSaving(false);
          setToastInfo({
            message: "Retouching successfully created",
            background: "success",
          });
          setIsShowToast(true);
          const updatedRetouching = retouching;
          updatedRetouching[index].uuid = res.data.id;
          setRetouching(updatedRetouching);
          // console.log(updatedRetouching[index]);
          exportPDF(updatedRetouching[index]);
        })
        .catch((err) => {
          setIsSaving(false);
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "Retouching failed created",
            background: "danger",
          });
          setIsShowToast(true);
        });
    } else {
      UpdateRetouching(item)
        .then(() => {
          setIsSaving(false);
          setToastInfo({
            message: "Retouching successfully updated",
            background: "success",
          });
          setIsShowToast(true);
          exportPDF(item);
        })
        .catch((err) => {
          setIsSaving(false);
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "Retouching failed updated",
            background: "danger",
          });
          setIsShowToast(true);
        });
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    let size = null;
    const converter = 2.204;

    const pounds = converter * value;
    if (name === "weight") {
      if (pounds < 3) {
        size = 1;
      } else if (pounds >= 3 && pounds < 5) {
        size = 3;
      } else {
        size = 5;
      }
    }
    const updatedTableData = [...retouching];

    updatedTableData[index] = {
      ...updatedTableData[index],
      [name]: value,
    };

    if (size) {
      updatedTableData[index].size = size;
    }

    setRetouching(updatedTableData);
  };

  const handleDeleteRetouching = (uuid) => {
    setIsLoading(true);
    DeleteRetouching(uuid).then(() => {
      const updated = retouching.filter((item) => item.uuid !== uuid);

      setRetouching(updated);
      setIsLoading(false);
      setToastInfo({
        message: "Retouching successfully deleted",
        background: "success",
      });
      setIsShowToast(true);
    });
  };

  const handleAddNewLine = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    const formattedDate = yyyy + "-" + mm + "-" + dd;

    const newArray = [
      ...retouching,
      {
        retouchingDate: formattedDate,
        trimmingUuid: selectedTrimming.uuid,
        receivingUuid: selectedTrimming.receivingUuid,
        vendorUuid: selectedTrimming.vendorUuid,
        grade: "AAA",
        cuttingType: "A",
      },
    ];

    setRetouching(newArray);
  };

  const handleExportDaily = () => {
    ExportDailyRetouching(date)
      .then((response) => {
        const fileName = `retouching(${date.start} - ${date.end}).csv`;
        fileDownload(response.data, fileName);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "data not found",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div className="col-sm-3 mt-3">
              <input
                type="text"
                className="form-control"
                placeholder="input trimming code"
                ref={ref}
                maxLength="18"
                onChange={(e) => {
                  setKeyword(e.target.value);
                }}
              />
            </div>
            <div className="col-sm-3 mt-3 d-flex">
              <input
                type="date"
                className="form-control me-1"
                value={date.start}
                onChange={(e) => setDate({ ...date, start: e.target.value })}
              />
              <input
                type="date"
                className="form-control me-1"
                value={date.end}
                onChange={(e) => setDate({ ...date, end: e.target.value })}
              />
              <button
                className="btn btn-primary me-1"
                onClick={handleExportDaily}
              >
                Download
              </button>
            </div>
          </div>

          {isLoading ? (
            <Loading />
          ) : (
            <>
              {selectedReceiving && selectedTrimming && (
                <>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <h5 className="card-title">{keyword}</h5>
                    <div className="row align-items-center">
                      <button
                        className="btn btn-primary"
                        onClick={handleAddNewLine}
                      >
                        {" "}
                        <i className="bi bi-plus-lg"></i>
                      </button>
                    </div>
                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">Date</th>
                        <th scope="col">Type</th>
                        <th scope="col">Grade</th>
                        <th scope="col">Weight</th>
                        <th scope="col">Size</th>
                        <th scope="col">Certificate</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {retouching &&
                        retouching.map((item, index) => {
                          return (
                            <tr key={index}>
                              <th>
                                <input
                                  type="date"
                                  className="form-control"
                                  name="retouchingDate"
                                  value={item.retouchingDate}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                              </th>
                              <th>
                                <Form.Select
                                  name="cuttingType"
                                  onChange={(e) => handleInputChange(e, index)}
                                  value={item.cuttingType}
                                >
                                  <option value={null}>Select Type</option>
                                  <option value={"A"}>FULL</option>
                                  <option value={"B"}>HEAD</option>
                                  <option value={"C"}>TAIL</option>
                                </Form.Select>
                              </th>

                              <th>
                                <Form.Select
                                  name="grade"
                                  onChange={(e) => handleInputChange(e, index)}
                                  value={item.grade}
                                >
                                  <option value={null}>Select Grade</option>
                                  <option value={"AAA(AB)"}>AAA(AB)</option>
                                  <option value={"AAA"}>AAA</option>
                                  <option value={"AA"}>AA</option>
                                  <option value={"A"}>A</option>
                                  <option value={"LOCAL"}>LOCAL</option>
                                  <option value={"ASUKI"}>ASUKI</option>
                                  <option value={"B"}>B</option>
                                  <option value={"C"}>C</option>
                                  <option value={"A+"}>A+</option>
                                  <option value={"B+"}>B+</option>
                                </Form.Select>
                              </th>
                              <th>
                                <input
                                  type="number"
                                  className="form-control"
                                  name="weight"
                                  value={item.weight}
                                  onChange={(e) => handleInputChange(e, index)}
                                />
                              </th>
                              <th>
                                <Form.Select
                                  name="size"
                                  onChange={(e) => handleInputChange(e, index)}
                                  value={item.size}
                                >
                                  <option value={null}>Select size</option>
                                  <option value={1}>1-3 Lbs</option>
                                  <option value={3}>3-5 Lbs</option>
                                  <option value={5}>5 Lbs Up</option>
                                </Form.Select>
                              </th>
                              <th>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled
                                  value={vendor.certificateType}
                                />
                              </th>
                              <th>
                                <div style={{ display: "flex" }}>
                                  <button
                                    onClick={() => {
                                      handleDeleteRetouching(item.uuid);
                                    }}
                                    style={{
                                      background: "unset",
                                      border: "none",
                                    }}
                                  >
                                    <i className="bi bi-dash-lg"></i>
                                  </button>
                                  <button
                                    className="form-control"
                                    onClick={() => {
                                      handleSubmit(
                                        {
                                          ...item,
                                          lotCode: `${keyword}.${item.cuttingType}`,
                                        },
                                        index
                                      );
                                    }}
                                    style={{
                                      background: "unset",
                                      border: "none",
                                    }}
                                    disabled={isSaving}
                                  >
                                    {isSaving ? (
                                      <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                      ></span>
                                    ) : (
                                      <i className="bi bi-file-earmark-arrow-up"></i>
                                    )}
                                  </button>
                                  {/* <button
                                    className="form-control"
                                    onClick={() => {
                                      if (!item.uuid) {
                                        return;
                                      }
                                      exportPDF(item);
                                    }}
                                    style={{
                                      background: "unset",
                                      border: "none",
                                    }}
                                  >
                                    <i className="bi bi-printer"></i>
                                  </button> */}
                                </div>
                              </th>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
