import React, { useState, useRef } from "react";
import { DataTable } from "../../Components";
import CSMShipment from "../../API/CSMShipment";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { useModal } from "../../Hook/useModal";
import { Modal } from "react-bootstrap";
import { Input, ConfirmationModal } from "../../Components";
import { useFormik } from "formik";
import * as Yup from "yup";
import SummarizeModal from "./SummarizeModal";
import { debounce } from "lodash";

export default function DayTable({ selectedDate, setSelectedDate }) {
  const { lastDataModificationTimestamp, setToastInfo, setIsShowToast } =
    useApplicationStoreContext();
  const _csmshipment = new CSMShipment();
  const confirmationModal = useModal();
  const editModal = useModal();
  const summarizeModal = useModal();
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState(false);
  const ref = useRef();

  const tableHeadersCSM = [
    {
      name: "SAP Code",
    },
    {
      name: "lot",
    },
    {
      name: "Batch",
    },
    {
      name: "Super Batch",
    },
    {
      name: "Row",
    },
    {
      name: "Action",
    },
  ];

  const tableBodyCSM = [
    {
      name: "receivingDate",
      view: (data) => {
        return (
          <a
            href={`/view/${data.uuid}`}
            className={`test-${data.uuid}`}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {data.sap || data.destinationItem}
          </a>
        );
      },
    },
    {
      name: "lot",
      view: (data) => data.lot || data.destination_combined_lot,
    },
    {
      name: "batch",
      view: (data) => data.batch || data.destinationBatch,
    },
    {
      name: "superBatch",
      view: (data) => data.superBatch || data.destinationSuperBatch,
    },
    {
      name: "row",
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              onClick={() => {
                setSelected({
                  ...data,
                  row: { value: data.row, label: data.row },
                });
                editModal.toggleModal();
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1 me-2"
              onClick={() => {
                setSelected({
                  ...data,
                  row: { value: data.row, label: data.row },
                });
                confirmationModal.toggleModal();
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  const handleDelete = () => {
    _csmshipment
      .DeleteShipmentDetail(selected.uuid)
      .then(() => {
        setToastInfo({
          message: "packing successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setRefresh((prev) => !prev);
      })
      .catch(() => {
        setToastInfo({
          message: "packing failed to delet",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const formik = useFormik({
    initialValues: {
      row: "",
      superBatch: "",
    },
    validationSchema: Yup.object({
      row: Yup.object().required("Row is required"),
      superBatch: Yup.string().required("Super Batch is required"),
    }),
  });

  const handleReadQRCode = (e) => {
    const qr = e.target.value.split(/\s+/);

    const lot = qr[0];
    const sap = qr[1];
    const batch = qr[2];
    const type = qr[3];
    const superBatch = qr[4];
    const code = lot.charAt(lot.length - 1);

    if (!formik.values.row) {
      formik.setTouched({
        row: "",
      });
      ref.current.value = "";
      return;
    }

    if (qr.length === 5) {
      _csmshipment
        .ReadQRCode({ lot, sap, batch, superBatch, code, type })
        .then((response) => {
          const newValue = {
            lot,
            sap,
            batch,
            type,
            packingUuid: response.data.uuid,
            repackUuid: response.data.repackUuid,
            row: formik.values.row.value,
            shipmentUuid: selectedDate.uuid,
          };

          _csmshipment
            .CreateShipmentDetail(newValue)
            .then(() => {
              // setToastInfo({
              //   message: "Item successfully added",
              //   background: "success",
              // });
              // setIsShowToast(true);
              setRefresh((prev) => !prev);
            })
            .catch(() => {
              setToastInfo({
                message: "Item failed to added",
                background: "danger",
              });
              setIsShowToast(true);
            });

          ref.current.value = "";
        });
    }
  };

  const rowOptions = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
    { value: 6, label: 6 },
    { value: 7, label: 7 },
    { value: 8, label: 8 },
    { value: 9, label: 9 },
    { value: 10, label: 10 },
    { value: 11, label: 11 },
    { value: 12, label: 12 },
    { value: 13, label: 13 },
    { value: 14, label: 14 },
    { value: 15, label: 15 },
    { value: 16, label: 16 },
    { value: 17, label: 17 },
    { value: 18, label: 18 },
    { value: 19, label: 19 },
    { value: 20, label: 20 },
    { value: 21, label: 21 },
    { value: 22, label: 22 },
    { value: 23, label: 23 },
    { value: 24, label: 24 },
    { value: 25, label: 25 },
    { value: 26, label: 26 },
    { value: 27, label: 27 },
    { value: 28, label: 28 },
    { value: 29, label: 29 },
    { value: 30, label: 30 },
    { value: 31, label: 31 },
  ];

  const handleUpdateShipment = () => {
    const newValue = { ...selected, row: selected.row.value };
    _csmshipment
      .UpdateShipmentDetail(selected.uuid, newValue)
      .then(() => {
        setToastInfo({
          message: "Item successfully added",
          background: "success",
        });
        setIsShowToast(true);
        setRefresh((prev) => !prev);
        editModal.toggleModal();
      })
      .catch(() => {
        setToastInfo({
          message: "Item failed to added",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const handleDebouncedChange = debounce((value) => {
    handleReadQRCode(value);
  }, 500);

  return (
    <div className={`col-lg-12`}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">{selectedDate.po}</h5>
          <div className="d-flex justify-content-between">
            <div className="mt-3 row">
              <div className="col-md-6">
                <label htmlFor="code">Packing Code</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Scan QR Packing Line 2"
                  onChange={(e) => handleDebouncedChange(e)}
                  ref={ref}
                />
              </div>
              <div className="col-md-6">
                <label htmlFor="row">Row</label>
                <Input
                  type="select"
                  name="row"
                  useLabel={false}
                  onChange={formik.handleChange}
                  value={formik.values.row}
                  errorMessage={formik.errors?.row}
                  isError={formik.errors.row}
                  options={rowOptions}
                />
              </div>
            </div>
            <div className="d-flex align-items-center gap-2 justify-content-end">
              <button
                className="btn btn-primary"
                onClick={summarizeModal.toggleModal}
              >
                Summarize
              </button>
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedDate();
                }}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <DataTable
            api={_csmshipment.PagedSearchShipmentDetail}
            params={{
              keyword: "",
              shipmentUuid: selectedDate.uuid,
            }}
            tableHeader={tableHeadersCSM}
            tableBody={tableBodyCSM}
            dependencies={[
              selectedDate,
              lastDataModificationTimestamp,
              refresh,
            ]}
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirmationModal.modal}
        toggle={confirmationModal.toggleModal}
        onSubmit={handleDelete}
        message={"Are you sure delete this item?"}
      />
      <Modal show={editModal.modal} onHide={editModal.toggleModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Shipment Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Input
              type="select"
              name="row"
              label="Row"
              onChange={(e) =>
                setSelected((prev) => ({ ...prev, row: e.target.value }))
              }
              value={selected?.row}
              options={rowOptions}
            />
            <Input
              type="text"
              name="supertBatch"
              label="Super Batch"
              onChange={(e) =>
                setSelected((prev) => ({ ...prev, superBatch: e.target.value }))
              }
              value={selected?.superBatch}
            />
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={editModal.toggleModal}>
            Close
          </button>
          <button className="btn btn-primary" onClick={handleUpdateShipment}>
            Update
          </button>
        </Modal.Footer>
      </Modal>
      <SummarizeModal
        show={summarizeModal.modal}
        toggle={summarizeModal.toggleModal}
        shipmentUuid={selectedDate.uuid}
      />
    </div>
  );
}
