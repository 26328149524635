import React from "react";
import { DataTable } from "../../Components";
import CSMTrimming from "../../API/CSMTrimming";
import { useApplicationStoreContext } from "../../Hook/UserHook";

export default function SupplierTable({
  setVendor,
  setDailyDate,
  dailyDate,
  vendor,
  selectedDate,
}) {
  const { lastDataModificationTimestamp } = useApplicationStoreContext();
  const _CSMTrimming = new CSMTrimming();

  const tableHeadersCSM = [
    {
      name: "RM Lot Code",
    },
    {
      name: "Reff",
    },
  ];

  const tableBodyCSM = [
    {
      name: "rmLotCode",
      view: (data) => {
        return (
          <a
            href={`/view/${data.uuid}`}
            onClick={(e) => {
              e.preventDefault();
              setVendor(data);
            }}
          >
            {data.rmLotCode}
          </a>
        );
      },
    },
    {
      name: "reff",
    },
  ];

  return (
    <div className={`col-lg-6`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">{dailyDate}</h5>
            <div className="row align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setVendor();
                  setDailyDate();
                }}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <DataTable
            api={_CSMTrimming.ReadTrimmingByVendor}
            params={{
              date: dailyDate,
            }}
            tableHeader={tableHeadersCSM}
            tableBody={tableBodyCSM}
            dependencies={[
              dailyDate,
              lastDataModificationTimestamp,
              selectedDate,
            ]}
            activeClassName={(item) => {
              if (item?.uuid === vendor?.uuid) {
                return "active";
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
