import React, { useState, useEffect } from "react";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { ModalPopUp, DataTable } from "../../Components";
import CreateForm from "./CreateForm";
import Retouching from "../../API/CSMRetouching";
import { Action } from "../../Constant";
import { handleSizeCSMTrimming } from "../../Utility";
import moment from "moment";

export default function DetailTable({
  vendor,
  dailyDate,
  setVendor,
  selectedDate,
}) {
  const _retouching = new Retouching();
  const {
    setToastInfo,
    setIsShowToast,
    lastDataModificationTimestamp,
    setLastDataModificationTimestamp,
  } = useApplicationStoreContext();
  const [isShowModal, setIsShowModal] = useState();
  const [selectedData, setSeledtedData] = useState();
  const [action, setAction] = useState();
  const [summarize, setSummarize] = useState();

  const deleteData = () => {
    _retouching
      .DeleteRetouching(selectedData.uuid)
      .then(() => {
        setToastInfo({
          message: "Receiving successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setIsShowModal(false);
        setLastDataModificationTimestamp(new Date());
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response?.status === 403
              ? err.response?.data?.message
              : "Receiving failed deleted",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const deleteModal = () => {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Delete Retouching</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">{`Are you sure delete ${selectedData.lot} ?`}</div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsShowModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteData()}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  const updateModal = () => {
    if (!selectedData) {
      return;
    }

    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Update Retouching</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsShowModal(false)}
          />
        </div>
        <div className="modal-body">
          <CreateForm uuid={selectedData.uuid} setIsOpen={setIsShowModal} />
        </div>
      </div>
    );
  };

  const component = () => {
    if (action === Action.DELETE) {
      return deleteModal();
    } else {
      return updateModal();
    }
  };

  const tableHeadersCSM = [
    {
      name: "RM Lot",
    },
    {
      name: "CSM Lot",
    },
    {
      name: "Type",
    },
    {
      name: "PDC",
    },
    {
      name: "Vessel",
    },
    {
      name: "Origin",
    },
    {
      name: "Trip Date",
    },
    {
      name: "grade",
    },
    {
      name: "Weight",
    },
    {
      name: "Size",
    },
    {
      name: "Action",
    },
  ];

  const tableBodyCSM = [
    {
      name: "lot",
      view: () => vendor.rmLotCode,
    },
    {
      name: "receivingLot",
    },
    {
      name: "materialType",
    },
    {
      name: "receivingDate",
      view: (data) => moment(data.receivingDate).format("DD/MM/YY"),
    },
    {
      name: "vesselName",
    },
    {
      name: "origin",
    },
    {
      name: "tripDate",
    },
    {
      name: "grade",
    },
    {
      name: "weight",
    },
    {
      name: "weight",
      view: (data) => {
        return handleSizeCSMTrimming(data.weight);
      },
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              data-bs-toggle="modal"
              data-bs-target="#verticalycentered"
              onClick={() => {
                setSeledtedData(data);
                setAction(Action.EDIT);
                setIsShowModal(true);
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-success py-0 px-1 me-2"
              data-bs-toggle="modal"
              data-bs-target="#verticalycentered"
              onClick={() => {
                var textToCopy = `${data.rmLotCode}/${data.grade}/${
                  data.weight
                }/${data.reff + moment(data.receivingDate).format("DDMMYY")}/${
                  data.origin
                }/${moment(data.receivingDate).format("YYYY-MM-DD")}/${
                  data.materialType
                }/${moment(data.retouchingDate).format("YYYY-MM-DD")}`;

                // Copy the text to the clipboard
                navigator.clipboard
                  .writeText(textToCopy)
                  .then(() => {
                    // Inform the user
                    alert("Text has been copied: " + textToCopy);
                  })
                  .catch((err) => {
                    console.error("Could not copy text: ", err);
                  });
              }}
            >
              <i class="bi bi-c-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1"
              data-bs-toggle="modal"
              data-bs-target="#verticalycentered"
              onClick={() => {
                setSeledtedData(data);
                setAction(Action.DELETE);
                setIsShowModal(true);
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (vendor.uuid) {
      _retouching.SummarizeDetail(vendor.uuid, dailyDate).then((response) => {
        setSummarize(response.data);
      });
    }
  }, [vendor, lastDataModificationTimestamp]);

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <div>
              <h5 className="card-title">
                {vendor.rmLotCode}/{dailyDate}
              </h5>
            </div>

            <div className="row align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setVendor();
                }}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-4">
              <table className="table">
                <thead>
                  <tr>
                    <th>Cateory</th>
                    <th>Total Weight</th>
                    <th>Total Count</th>
                  </tr>
                </thead>
                <tbody>
                  {summarize?.map((item, index) => {
                    return (
                      <tr className="" key={index}>
                        <td>{item.weightRange}</td>
                        <td className="mb-0">
                          {parseFloat(item.totalWeight).toFixed(2)}
                        </td>
                        <td>{item.totalCount}</td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <th>
                      {summarize
                        ?.reduce((acc, item) => acc + item.totalWeight, 0)
                        .toFixed(2)}
                      Kgs
                    </th>
                    <th>
                      {summarize?.reduce(
                        (acc, item) => acc + item.totalCount,
                        0
                      )}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <DataTable
            api={_retouching.ReadRetouchingDetail}
            params={{
              date: dailyDate,
              receivingUuid: vendor.uuid,
            }}
            tableHeader={tableHeadersCSM}
            tableBody={tableBodyCSM}
            dependencies={[
              vendor,
              lastDataModificationTimestamp,
              dailyDate,
              selectedDate,
            ]}
          />
        </div>
      </div>
      <ModalPopUp
        component={component}
        isOpen={isShowModal}
        setIsOpen={setIsShowModal}
      />
    </div>
  );
}
