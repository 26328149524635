import React, { useState } from "react";
import { DataTable } from "../../Components";
import CSMLeftOver from "../../API/CSMLeftOver";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { useModal } from "../../Hook/useModal";
import { ConfirmationModal } from "../../Components";
import { Modal } from "react-bootstrap";
import CreateFormDetail from "./CreateFormDetail";
import moment from "moment";

export default function DayTable({
  selectedDate,
  setDailyDate,
  setSelectedDate,
}) {
  const { lastDataModificationTimestamp, setToastInfo, setIsShowToast } =
    useApplicationStoreContext();
  const _csmleftover = new CSMLeftOver();
  const confirmationModal = useModal();
  const editModal = useModal();
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState(false);

  const tableHeadersCSM = [
    {
      name: "Lot",
    },
    {
      name: "Type",
    },
    {
      name: "Item",
    },
    {
      name: "Batch",
    },
    {
      name: "Weight",
    },
    {
      name: "Left Over Packed",
    },
    {
      name: "Remaining Qty",
    },
    {
      name: "Packing Date",
    },
    {
      name: "Grade",
    },
    {
      name: "Action",
    },
  ];

  const tableBodyCSM = [
    {
      name: "lot",
    },
    {
      name: "item",
    },
    {
      name: "sap",
    },
    {
      name: "batch",
    },
    {
      name: "qty",
    },
    {
      name: "used",
    },
    {
      name: "used",
      view: (data) => {
        return parseFloat(data.qty - data.used).toFixed(2);
      },
    },
    {
      name: "packingDate",
    },
    {
      name: "grade",
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                editModal.toggleModal();
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                confirmationModal.toggleModal();
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  const handleDelete = () => {
    _csmleftover
      .DeleteLeftOverDetail(selected.detailUuid)
      .then(() => {
        setToastInfo({
          message: "left over successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setRefresh((prev) => !prev);
      })
      .catch(() => {
        setToastInfo({
          message: "left over failed to delet",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  return (
    <div className={`col-lg-12`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">
              {moment(selectedDate.pdc).format("DD-MM-YYYY")}
            </h5>
            <div className="d-flex gap-2 align-items-center">
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  setSelected();
                  editModal.toggleModal();
                }}
              >
                <i className="bi bi-plus-lg"></i>
              </button>
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();

                  setDailyDate();
                  setSelectedDate();
                }}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <DataTable
            api={_csmleftover.LeftOverDetailSearh}
            params={{
              keyword: "",
              uuid: selectedDate.uuid,
            }}
            tableHeader={tableHeadersCSM}
            tableBody={tableBodyCSM}
            dependencies={[
              selectedDate,
              lastDataModificationTimestamp,
              refresh,
            ]}
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirmationModal.modal}
        toggle={confirmationModal.toggleModal}
        onSubmit={handleDelete}
        message={"Are you sure delete this item?"}
      />
      <Modal show={editModal.modal} onHide={editModal.toggleModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Edit Left Over</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateFormDetail
            uuid={selected?.detailUuid}
            setIsOpen={editModal.toggleModal}
            setRefresh={setRefresh}
            leftoverUuid={selectedDate?.uuid}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}
