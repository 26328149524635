import React, { useEffect, useState } from "react";
import { CreateShipment, UpdateShipment } from "../../API";
import { useApplicationStoreContext } from "../../Hook/UserHook";

export default function Create({ setIsShowModal, editShipment, setIsReload }) {
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const initialData = {
    invoice: "",
    packingList: "",
    etd: "",
    bl: "",
    container: "",
    seal: "",
    vesselName: "",
    shipmentRef: "",
    portLoading: "",
    portDischarged: "",
    po: "",
  };

  const [data, setData] = useState(initialData);

  useEffect(() => {
    if (editShipment) {
      setData(editShipment);
    }
  }, [editShipment]);

  const onChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setData((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (editShipment) {
      UpdateShipment(editShipment.uuid, data).then((response) => {
        setToastInfo({
          message: "Shipment successfully updated",
          background: "success",
        });
        setIsShowToast(true);
        setIsShowModal(false);
        setIsReload((prev) => !prev);
      });
    } else {
      CreateShipment(data).then((response) => {
        setToastInfo({
          message: "Shipment successfully created",
          background: "success",
        });
        setIsShowToast(true);
        setIsShowModal(false);
        setIsReload((prev) => !prev);
      });
    }
  };

  return (
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Create Shipment</h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => setIsShowModal(false)}
        />
      </div>
      <div className="modal-body">
        <div className="col-lg-12">
          <form>
            <div className="row mb-3">
              <label htmlFor="invoice" className="col-sm-2 col-form-label">
                INV No
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="invoice"
                  onChange={onChange}
                  name="invoice"
                  value={data.invoice}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="packingList" className="col-sm-2 col-form-label">
                PL No
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="packingList"
                  onChange={onChange}
                  name="packingList"
                  value={data.packingList}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="etd" className="col-sm-2 col-form-label">
                ETD
              </label>
              <div className="col-sm-10">
                <input
                  type="date"
                  className="form-control"
                  id="etd"
                  onChange={onChange}
                  name="etd"
                  value={data.etd}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="bl" className="col-sm-2 col-form-label">
                BL No
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="bl"
                  onChange={onChange}
                  name="bl"
                  value={data.bl}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="container" className="col-sm-2 col-form-label">
                Container
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="container"
                  onChange={onChange}
                  name="container"
                  value={data.container}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="seal" className="col-sm-2 col-form-label">
                Seal No
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="seal"
                  onChange={onChange}
                  name="seal"
                  value={data.seal}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="vesselName" className="col-sm-2 col-form-label">
                Vessel
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="vesselName"
                  onChange={onChange}
                  name="vesselName"
                  value={data.vesselName}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="shipmentRef" className="col-sm-2 col-form-label">
                Ref
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="shipmentRef"
                  onChange={onChange}
                  name="shipmentRef"
                  value={data.shipmentRef}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="portLoading" className="col-sm-2 col-form-label">
                Port
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="portLoading"
                  onChange={onChange}
                  name="portLoading"
                  value={data.portLoading}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label
                htmlFor="portDischarged"
                className="col-sm-2 col-form-label"
              >
                Discharged
              </label>
              <div className="col-sm-10">
                <input
                  type="text"
                  className="form-control"
                  id="portDischarged"
                  onChange={onChange}
                  name="portDischarged"
                  value={data.portDischarged}
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="po" className="col-sm-2 col-form-label">
                SAP PO
              </label>
              <div className="col-sm-10">
                <input
                  type="number"
                  className="form-control"
                  id="po"
                  onChange={onChange}
                  name="po"
                  value={data.po}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-sm-12 d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={handleSubmit}
                >
                  Create
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
