import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import ShipmentTable from "./Shipment";
import Detail from "./Detail";

export default function Index() {
  const [selectedDate, setSelectedDate] = useState();

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Shipment</h1>
      </div>
      <section className="section">
        <div className="row">
          <ShipmentTable
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
          />
          <Outlet />
          {selectedDate && (
            <Detail
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
          )}
        </div>
      </section>
    </main>
  );
}
