import React, { useState } from "react";
import { intToMonth } from "../../Utility";
import CSMReceiving from "../../API/CSMReceiving";
import { ExportDailyReceiving } from "../../API";
import { ModalPopUp, DataTable } from "../../Components";
import CreateForm from "./CreateForm";
import fileDownload from "js-file-download";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import moment from "moment";

export default function MonthlyTable({ setSelectedDate, selectedDate }) {
  const _CSMReceiving = new CSMReceiving();

  const { setToastInfo, setIsShowToast, lastDataModificationTimestamp } =
    useApplicationStoreContext();
  const formattedDate = moment(new Date()).format("YYYY-MM-DD");
  const [isShowModal, setIsShowModal] = useState(false);
  const [date, setDate] = useState({
    start: formattedDate,
    end: "",
  });

  const component = () => {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create Receiving</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsShowModal(false)}
          />
        </div>
        <div className="modal-body">
          <CreateForm setIsOpen={setIsShowModal} />
        </div>
      </div>
    );
  };

  const handleExportDaily = () => {
    _CSMReceiving
      .ExportReceivingDaily(date)
      .then((response) => {
        const fileName = `receiving(${date.start} - ${date.end}).csv`;
        fileDownload(response.data, fileName);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "data not found",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const tableHeader = [
    {
      name: "Year",
    },
    {
      name: "Month",
    },
  ];

  const tableBody = [
    {
      name: "year",
      view: (data) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedDate({
                year: data.year,
                month: data.month,
              });
            }}
          >
            {data.year}
          </a>
        );
      },
    },
    {
      name: "month",
      view: (data) => intToMonth(data.month),
    },
  ];

  return (
    <div className={`col-lg-12`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">Receiving</h5>
            <div className="align-items-center d-flex justify-content-between">
              <div className="d-flex">
                <input
                  type="date"
                  className="form-control me-1"
                  value={date.start}
                  onChange={(e) => setDate({ ...date, start: e.target.value })}
                />
                <input
                  type="date"
                  className="form-control me-1"
                  value={date.end}
                  onChange={(e) => setDate({ ...date, end: e.target.value })}
                />
                <button
                  className="btn btn-primary me-1"
                  onClick={handleExportDaily}
                >
                  Download
                </button>
                <button
                  className="btn btn-primary me-1"
                  onClick={() => setIsShowModal(true)}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
          <DataTable
            api={_CSMReceiving.ReadReceivingByMonth}
            pageSize={10}
            tableHeader={tableHeader}
            tableBody={tableBody}
            dependencies={[lastDataModificationTimestamp]}
            activeClassName={(item) => {
              if (
                item?.year === selectedDate?.year &&
                item?.month === selectedDate?.month
              ) {
                return "active";
              }
            }}
          />
        </div>
      </div>
      <ModalPopUp
        component={component}
        isOpen={isShowModal}
        setIsOpen={setIsShowModal}
      />
    </div>
  );
}
