import React, { useEffect, useState } from "react";
import { PackingMonthly, DeletePacking } from "../../API/packing";
import { intToMonth, addLeadingZero } from "../../Utility";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import Pagination from "../../Components/Pagination";
import { Loading } from "../../Components";

export default function Monthly({
  selectedDate,
  setSelectedDate,
  setSelectedDetail,
  selectedDetail,
}) {
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const [data, setData] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 10;

  const getPacking = () => {
    setIsLoading(true);
    PackingMonthly(
      pageIndex,
      pageSize,
      selectedDate.year,
      selectedDate.month
    ).then((response) => {
      setData(response.data.data);
      setTotalCount(response.data.totalCount);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (selectedDate) {
      getPacking();
    }
  }, [selectedDate, pageIndex]);

  const month = selectedDate && addLeadingZero(selectedDate.month, 2);
  const year = selectedDate && selectedDate.year.toString().substring(2);

  if (!selectedDate) {
    return null;
  }

  const handleDelete = (item) => {
    DeletePacking(item.uuid)
      .then((response) => {
        setToastInfo({
          message: "packing successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        getPacking();
        setSelectedDetail();
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "packing failed to deleted",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="col-lg-6">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">
              {intToMonth(selectedDate.month)} {selectedDate.year}
            </h5>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedDate();
                  setSelectedDetail();
                }}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">Case No</th>
                <th scope="col">Grade</th>
                <th scope="col">Packing Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={
                        selectedDetail?.uuid === item.uuid
                          ? "active"
                          : undefined
                      }
                    >
                      <th scope="row">
                        {" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedDetail({
                              ...item,
                              boxNo: `MT-${year}${addLeadingZero(
                                month,
                                2
                              )}.${addLeadingZero(item.caseNo, 4)}`,
                            });
                          }}
                        >
                          {`MT-${year}${addLeadingZero(
                            month,
                            2
                          )}.${addLeadingZero(item.caseNo, 4)}`}{" "}
                        </a>
                      </th>
                      <td>{item.grade}</td>
                      <td>{item.packingDate}</td>
                      <td>
                        <button
                          className="btn btn-danger py-0 px-1"
                          onClick={() => handleDelete(item)}
                        >
                          <i className="bi bi-trash3"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {totalCount !== 0 && totalCount > pageSize && (
            <nav>
              <Pagination
                pageSize={pageSize}
                totalCount={totalCount}
                pageOffset={pageOffset}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPageIndex={setPageIndex}
                setPageOffset={setPageOffset}
              />
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}
