import React, { useEffect, useState } from "react";
import { PagedSearchPacking } from "../../API";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { intToMonth } from "../../Utility";
import Pagination from "../../Components/Pagination";
import Loading from "../../Components/Loading";

export default function Yearly({
  selectedDate,
  setSelectedDate,
  setSelectedDetail,
}) {
  const { lastDataModificationTimestamp } = useApplicationStoreContext();
  const [packingGrid, setPackingGrid] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 10;

  useEffect(() => {
    setIsLoading(true);
    PagedSearchPacking(pageIndex, pageSize).then((response) => {
      setPackingGrid(response.data.data);
      setTotalCount(response.data.totalCount);
      setIsLoading(false);
    });
  }, [lastDataModificationTimestamp, pageIndex]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className={`col-lg-${selectedDate ? "6" : "12"}`}>
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Packing Data</h5>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Year</th>
                <th scope="col">Month</th>
                <th scope="col">Total Box</th>
              </tr>
            </thead>
            <tbody>
              {packingGrid &&
                packingGrid.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={
                        selectedDate?.month === item.month &&
                        selectedDate?.year === item.year
                          ? "active"
                          : undefined
                      }
                    >
                      <th scope="row">
                        {" "}
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedDate({
                              year: item.year,
                              month: item.month,
                            });
                            setSelectedDetail();
                          }}
                        >
                          {item.year}
                        </a>
                      </th>
                      <td>{intToMonth(item.month)}</td>
                      <td>{item.count}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {totalCount !== 0 && totalCount > pageSize && (
            <nav>
              <Pagination
                pageSize={pageSize}
                totalCount={totalCount}
                pageOffset={pageOffset}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPageIndex={setPageIndex}
                setPageOffset={setPageOffset}
              />
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}
