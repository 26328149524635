import React, { useState } from "react";
import Monthly from "./Monthly";
import DetailCS from "./DetailCS";

export default function CsIndo() {
  const [selectedDetail, setSelectedDetail] = useState();

  return (
    <>
      <Monthly
        setSelectedDetail={setSelectedDetail}
        selectedDetail={selectedDetail}
      />
      {selectedDetail && (
        <DetailCS
          setSelectedDetail={setSelectedDetail}
          selectedDetail={selectedDetail}
        />
      )}
    </>
  );
}
