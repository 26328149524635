import React, { useEffect, useState } from "react";
import PackingModel from "../../API/CSMPacking";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import Pagination from "../../Components/Pagination";
import { Loading, Input } from "../../Components";
import moment from "moment";
import { handleSizeLBSRetouching } from "../../Utility";
import { ConfirmationModal } from "../../Components";
import { useModal } from "../../Hook/useModal";

export default function Monthly({ setSelectedDetail, selectedDetail }) {
  const _packing = new PackingModel();
  const { setIsShowToast, setToastInfo, lastDataModificationTimestamp } =
    useApplicationStoreContext();
  const [data, setData] = useState();
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageOffset, setPageOffset] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [packingDate, setPackingDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const pageSize = 10;
  const modalConfirmation = useModal();
  const [selected, setSelected] = useState();

  const getPacking = () => {
    setIsLoading(true);
    _packing
      .ReadPackingByMonth({
        pageIndex,
        pageSize,
        packingDate,
      })
      .then((response) => {
        setData(response.data.data);
        setTotalCount(response.data.totalCount);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPacking();
  }, [packingDate, pageIndex, lastDataModificationTimestamp]);

  const handleDelete = () => {
    _packing
      .DeletePacking({ uuid: selected.uuid })
      .then((response) => {
        setToastInfo({
          message: "packing successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        getPacking();
        setSelectedDetail();
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "packing failed to deleted",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="col-lg-12s">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <Input
              label="Date"
              type="date"
              name="receivingDate"
              containerClassName="mt-2"
              onChange={(e) => setPackingDate(e.target.value)}
              value={packingDate}
            />
          </div>

          <table className="table">
            <thead>
              <tr>
                <th scope="col">Sack No</th>
                <th scope="col">Grade</th>
                <th scope="col">Size</th>
                <th scope="col">Packing Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={
                        selectedDetail?.packingDate === item.packingDate &&
                        selectedDetail.sackNo === item.sackNo
                          ? "active"
                          : undefined
                      }
                    >
                      <th scope="row">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            setSelectedDetail(item);
                          }}
                        >
                          {item.sackNo}
                        </a>
                      </th>
                      <td>{item.grade}</td>
                      <td>
                        {handleSizeLBSRetouching(
                          parseFloat(item.weight).toFixed(2)
                        )}
                      </td>
                      <td>{item.packingDate}</td>
                      <td>
                        <button
                          className="btn btn-danger py-0 px-1"
                          onClick={() => {
                            setSelected(item);
                            modalConfirmation.toggleModal();
                          }}
                        >
                          <i className="bi bi-trash3"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {totalCount !== 0 && totalCount > pageSize && (
            <nav>
              <Pagination
                pageSize={pageSize}
                totalCount={totalCount}
                pageOffset={pageOffset}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPageIndex={setPageIndex}
                setPageOffset={setPageOffset}
              />
            </nav>
          )}
        </div>
      </div>
      <ConfirmationModal
        isOpen={modalConfirmation.modal}
        toggle={modalConfirmation.toggleModal}
        message={`are you sure want to delete?`}
        onSubmit={handleDelete}
      />
    </div>
  );
}
