import React, { useEffect, useState } from "react";
import CSMPacking2 from "../../API/CSMPacking2";
import CSMLeftOver from "../../API/CSMLeftOver";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { Input } from "../../Components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Action } from "../../Constant";

export default function CreateForm({
  uuid,
  setIsOpen,
  setRefresh,
  leftoverUuid,
}) {
  const { setToastInfo, setIsShowToast } = useApplicationStoreContext();
  const _csmpacking = new CSMPacking2();
  const _csmleftover = new CSMLeftOver();
  const [action, setAction] = useState(Action.CREATE);

  const formik = useFormik({
    initialValues: {
      item: "",
      sap: "",
      batch: "",
      grade: "",
      used: "",
      lot: "",
      qty: "",
    },
    validationSchema: Yup.object({
      item: Yup.object().required("Item is required"),
      qty: Yup.number().required("Qty is required"),
    }),
    onSubmit: (values) => {
      const data = {
        item: values.item.value,
        sap: values.sap.value,
        batch: values.batch,
        grade: values.grade?.value,
        qty: values.qty || null,
        used: values.used || null,
        lot: values.lot || null,
        packingDate: values.packingDate || null,
        leftoverUuid,
      };

      if (action === Action.CREATE) {
        _csmleftover
          .CreateLeftOverDetail(data)
          .then((response) => {
            setToastInfo({
              message: "Left Over successfully created",
              background: "success",
            });
            setIsShowToast(true);
            setIsOpen();
            setRefresh((prev) => !prev);
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Left Over failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        _csmleftover
          .UpdateLeftOverDetail(uuid, data)
          .then((response) => {
            setToastInfo({
              message: "Left Over successfully updated",
              background: "success",
            });
            setIsShowToast(true);
            setIsOpen();
            setRefresh((prev) => !prev);
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Receiving failed updated",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  useEffect(() => {
    if (uuid) {
      setAction(Action.EDIT);
      _csmleftover.GetLeftOverDetail2(uuid).then((response) => {
        const update = {
          ...response.data,
          grade: { value: response.data.grade, label: response.data.grade },
          sap: { value: response.data.sap, label: response.data.sap },
          item: { value: response.data.item, label: response.data.item },
        };
        formik.setValues(update);
      });
    }
  }, [uuid]);

  return (
    <form>
      <Input
        label="Type"
        type="select"
        name="item"
        onChange={formik.handleChange}
        value={formik.values.item}
        placeholder="Select Grade"
        errorMessage={formik.errors?.item}
        isError={formik.errors.item && formik.touched.item}
        options={[
          { value: "Tune Pieces", label: "Tuna Pieces" },
          { value: "Left Over", label: "Left Over" },
          { value: "FG Non Ready", label: "FG Non Ready" },
          { value: "Ground Meat", label: "Ground Meat" },
          { value: "Poke", label: "Poke" },
        ]}
      />
      <Input
        label="Item"
        type="select-api"
        name="sap"
        onChange={formik.handleChange}
        value={formik.values.sap}
        placeholder="Select SAP"
        errorMessage={formik.errors?.sap}
        isError={formik.errors.sap && formik.touched.sap}
        api={_csmpacking.GetSAPList}
        handleSetOptions={(pt) => ({ value: pt.code, label: pt.code })}
      />
      <Input
        label="Weight"
        type="number"
        name="qty"
        onChange={formik.handleChange}
        value={formik.values.qty}
        placeholder="Input Qty Kgs"
        errorMessage={formik.errors?.qty}
        isError={formik.errors.qty && formik.touched.qty}
      />
      <Input
        label="Left Over Packed"
        type="number"
        name="used"
        onChange={formik.handleChange}
        value={formik.values.used}
        placeholder="Input Left over packed kgs"
        errorMessage={formik.errors?.used}
        isError={formik.errors.used && formik.touched.used}
      />
      <Input
        label="Batch"
        type="text"
        name="batch"
        onChange={formik.handleChange}
        value={formik.values.batch}
        placeholder="Input Batch"
        errorMessage={formik.errors?.batch}
        isError={formik.errors.batch && formik.touched.batch}
      />
      <Input
        label="LOT"
        type="text"
        name="lot"
        onChange={formik.handleChange}
        value={formik.values.lot}
        placeholder="Input LOT"
        errorMessage={formik.errors?.lot}
        isError={formik.errors.lot && formik.touched.lot}
      />
      <Input
        label="Grade"
        type="select"
        name="grade"
        onChange={formik.handleChange}
        value={formik.values.grade}
        placeholder="Select Grade"
        errorMessage={formik.errors?.grade}
        isError={formik.errors.grade && formik.touched.grade}
        options={[
          { value: "A", label: "A" },
          { value: "AA", label: "AA" },
          { value: "AAA", label: "AAA" },
          { value: "B", label: "B" },
          { value: "AJ", label: "AJ" },
          { value: "A1", label: "A1" },
          { value: "A2", label: "A2" },
        ]}
      />
      <Input
        label="Packing Date"
        type="date"
        name="packingDate"
        onChange={formik.handleChange}
        value={formik.values.packingDate}
        placeholder="Input Packing Date"
        errorMessage={formik.errors?.packingDate}
        isError={formik.errors.packingDate && formik.touched.packingDate}
      />
      <div className="row mb-3">
        <div className=" d-flex justify-content-end">
          <button
            type="submit"
            className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen();
            }}
          >
            Close
          </button>
          <button
            type="submit"
            className="ms-2 btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            {action === Action.CREATE ? "Create" : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
}
