import React, { useState } from "react";
import { DataTable } from "../../Components";
import CSMPacking from "../../API/CSMPacking2";
import { intToMonth } from "../../Utility";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import moment from "moment";
import { useModal } from "../../Hook/useModal";
import { ConfirmationModal } from "../../Components";
import { Tooltip } from "react-tooltip";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import Modal from "./Modal";

export default function DayTable({
  selectedDate,
  setDailyDate,
  setSelectedDate,
}) {
  const { lastDataModificationTimestamp, setToastInfo, setIsShowToast } =
    useApplicationStoreContext();
  const _csmpacking = new CSMPacking();
  const confirmationModal = useModal();
  const editModal = useModal();
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState(false);
  const [detail, setDetail] = useState();

  const exportPdf = (lot, sap, batch, type, superBatch) => {
    var doc = new jsPDF("p", "mm", [50, 50]);

    QRCode.toDataURL(`${lot}\t${sap}\t${batch}\t${type}\t${superBatch}`, {
      margin: 1,
    })
      .then((lotCode) => {
        doc.addImage(lotCode, "JPEG", 5, 33, 14, 14);
        doc.setFontSize(11);
        doc.text(lot, 12, 30, null, 90);

        doc.addImage(lotCode, "JPEG", 32, 33, 14, 14);
        doc.setFontSize(11);
        doc.text(lot, 40, 30, null, 90);
        doc.autoPrint();
        var iframe = document.createElement("iframe");
        iframe.src = doc.output("datauristring");
        iframe.style.width = "0";
        iframe.style.height = "0";
        document.body.appendChild(iframe);
      })
      .catch((error) => console.log(error));
  };

  const tableHeadersCSM = [
    {
      name: "Lot",
    },
    {
      name: "SAP Code",
    },
    {
      name: "Batch",
    },
    {
      name: "PT",
    },
    {
      name: "PDC Line2",
    },
    {
      name: "Grade",
    },
    {
      name: "Qty",
    },
    {
      name: "Weight",
    },
    {
      name: "Super Batch",
    },
    {
      name: "Action",
    },
  ];

  const tableBodyCSM = [
    {
      name: "receivingDate",
      view: (data) => {
        return (
          <>
            <a
              href={`/view/${data.uuid}`}
              className={`test-${data.uuid}`}
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              {data.type === "A"
                ? data.lot + moment(data.pdc).format("DDMMYY") + data.code
                : data.lot + data.code}
            </a>
            <Tooltip
              anchorSelect={`.test-${data.uuid}`}
              place="top"
              afterShow={() => {
                _csmpacking
                  .TranslateIndoCode(data.lot)
                  .then((response) => setDetail(response.data))
                  .catch(() =>
                    setDetail({
                      supplierCode: "",
                      site: "",
                      grade: "",
                      certificate: "",
                    })
                  );
              }}
              afterHide={() => setDetail()}
            >
              {detail ? (
                `${detail.supplierCode} - ${detail.site} - ${detail.grade} - ${detail.certificate}`
              ) : (
                <div className="spinner-border spinner-border-sm" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              )}
            </Tooltip>
          </>
        );
      },
    },
    {
      name: "sap",
    },
    {
      name: "batch",
    },
    {
      name: "pt",
    },
    {
      name: "pdc",
      view: (data) => {
        return moment(data.pdc).format("DD-MM-YYYY");
      },
    },
    {
      name: "grade",
    },
    {
      name: "qty",
    },
    {
      name: "kgs",
      view: (data) => {
        return data.weight
          ? parseFloat(data.weight).toFixed(2)
          : parseFloat(data.kgs * data.qty).toFixed(2);
      },
    },
    {
      name: "superBatch",
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                editModal.toggleModal();
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-info text-white py-0 px-1 me-2"
              onClick={() => {
                exportPdf(
                  data.type === "A"
                    ? data.lot + moment(data.pdc).format("DDMMYY") + data.code
                    : data.lot + data.code,
                  data.sap,
                  data.batch,
                  data.type,
                  data.superBatch
                );
              }}
            >
              <i className="bi bi-printer"></i>
            </button>

            <button
              className="btn btn-success text-white py-0 px-1 me-2"
              onClick={() => {
                var textToCopy = `${
                  data.type === "A"
                    ? data.lot + moment(data.pdc).format("DDMMYY") + data.code
                    : data.lot + data.code
                }\t${data.sap}\t${data.batch}\t${data.type}\t${
                  data.superBatch
                }`;

                // Copy the text to the clipboard
                navigator.clipboard
                  .writeText(textToCopy)
                  .then(() => {
                    // Inform the user
                    alert("Text has been copied: " + textToCopy);
                  })
                  .catch((err) => {
                    console.error("Could not copy text: ", err);
                  });
              }}
            >
              <i className="bi bi-c-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                confirmationModal.toggleModal();
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  const handleDelete = () => {
    _csmpacking
      .DeletePacking(selected.uuid)
      .then(() => {
        setToastInfo({
          message: "packing successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setRefresh((prev) => !prev);
      })
      .catch(() => {
        setToastInfo({
          message: "packing failed to delet",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  return (
    <div className={`col-lg-12`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">
              {intToMonth(selectedDate.month)} {selectedDate.year}
            </h5>
            <div className="row align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();

                  setDailyDate();
                  setSelectedDate();
                }}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <DataTable
            api={_csmpacking.DetailGroup}
            params={{
              date: moment(selectedDate).format("YYYY-MM-DD"),
              keyword: "",
            }}
            tableHeader={tableHeadersCSM}
            tableBody={tableBodyCSM}
            dependencies={[
              selectedDate,
              lastDataModificationTimestamp,
              refresh,
            ]}
            activeClassName={(item) =>
              item.lastModifiedDateTime && "text-danger"
            }
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirmationModal.modal}
        toggle={confirmationModal.toggleModal}
        onSubmit={handleDelete}
        message={"Are you sure delete this item?"}
      />
      <Modal
        show={editModal.modal}
        toggleModal={editModal.toggleModal}
        setRefresh={setRefresh}
        uuid={selected?.uuid}
      />
    </div>
  );
}
