import React, { useState } from "react";
import CSMInventory from "../../API/CSMInventory";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { DataTable } from "../../Components";
import { useModal } from "../../Hook/useModal";
import { ConfirmationModal } from "../../Components";
import UpdateForm from "./UpdateForm";

export default function DetailCS({ setSelectedDetail, selectedDetail }) {
  const { setToastInfo, setIsShowToast } = useApplicationStoreContext();
  const _inventory = new CSMInventory();
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState(false);
  const confirmationModal = useModal();
  const updateModal = useModal();

  const handleDelete = (id) => {
    _inventory
      .DeleteIndoInventoryDetail(id)
      .then((response) => {
        setToastInfo({
          message: "successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setRefresh((prev) => !prev);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "data not found",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const tableHeader = [
    {
      name: "Case No",
    },
    {
      name: "Certificate",
    },
    {
      name: "Grade",
    },
    {
      name: "Fleet",
    },
    {
      name: "Weight",
    },
    {
      name: "Action",
    },
  ];

  const tableBody = [
    {
      name: "caseNumber",
    },
    {
      name: "certificate",
    },
    {
      name: "grade",
    },
    {
      name: "fleet",
    },
    {
      name: "boxWeight",
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                updateModal.toggleModal();
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1"
              onClick={() => {
                setSelected(data);
                confirmationModal.toggleModal();
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">{selectedDetail.vendor}</h5>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedDetail();
                }}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          </div>
          <DataTable
            api={_inventory.IndoInventoryDetail}
            params={{ vendor: selectedDetail?.vendor }}
            tableHeader={tableHeader}
            tableBody={tableBody}
            conditionalLoad={selectedDetail?.vendor}
            dependencies={[selectedDetail.vendor, refresh]}
            isSearch={true}
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirmationModal.modal}
        message={`Delete Data ${selected?.caseNumber} ?`}
        toggle={confirmationModal.toggleModal}
        onSubmit={() => handleDelete(selected?.uuid)}
      />
      <UpdateForm
        isOpen={updateModal.modal}
        toggle={updateModal.toggleModal}
        data={selected}
        setRefresh={setRefresh}
      />
    </div>
  );
}
