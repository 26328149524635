import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Action, MaterialType } from "../../Constant";
import * as Yup from "yup";
import {
  CreateVendor,
  ReadVendorByUuid,
  UpdateVendor,
  DeleteVendor,
} from "../../API";
import { ModalPopUp, Input, Button } from "../../Components";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { Certificate, Fleet, RawMaterial } from "../../Constant";

export default function VendorForm() {
  const {
    setIsShowModal,
    setLastDataModificationTimestamp,
    setToastInfo,
    setIsShowToast,
    isShowModal,
  } = useApplicationStoreContext();

  const navigate = useNavigate();
  const { uuid } = useParams();

  const [action, setAction] = useState(Action.CREATE);

  const formik = useFormik({
    initialValues: {
      supplierName: "",
      vendorCode: "",
      certificateType: "",
      fleet: "",
      owner: "",
      rawMaterialType: "",
    },
    validationSchema: Yup.object({
      supplierName: Yup.string().required("name is required"),
      vendorCode: Yup.string()
        .required("vendor code is required")
        .max(5, "maximum 5 char"),
      certificateType: Yup.object({
        value: Yup.string().required("required"),
        label: Yup.string().required("required"),
      }),
      fleet: Yup.object({
        value: Yup.string().required("required"),
        label: Yup.string().required("required"),
      }),
      owner: Yup.string().required("fisherman group is required"),
      rawMaterialType: Yup.object({
        value: Yup.string().required("required"),
        label: Yup.string().required("required"),
      }),
    }),
    onSubmit: () => {
      const updated = {
        ...formik.values,
        certificateType: formik.values.certificateType.value,
        fleet: formik.values.fleet.value,
        rawMaterialType: formik.values.rawMaterialType.value,
      };
      if (action === Action.CREATE) {
        CreateVendor(updated)
          .then((response) => {
            setToastInfo({
              message: "vendor successfully created",
              background: "success",
            });
            setIsShowToast(true);
            setLastDataModificationTimestamp(new Date().getTime());
            navigate("/vendor");
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "vendor failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        UpdateVendor(uuid, updated)
          .then((response) => {
            setToastInfo({
              message: "vendor successfully updated",
              background: "success",
            });
            setIsShowToast(true);
            setLastDataModificationTimestamp(new Date().getTime());
            navigate("/vendor");
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "vendor failed updated",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  const deleteData = () => {
    DeleteVendor(uuid)
      .then(() => {
        setToastInfo({
          message: "vendor successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setIsShowModal(false);
        setLastDataModificationTimestamp(new Date().getTime());
        navigate("/vendor");
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "vendor failed deleted",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  useEffect(() => {
    if (uuid) {
      setAction(Action.VIEW);
      ReadVendorByUuid(uuid).then((response) => {
        const selectedCertificate = Certificate.find(
          (a) => a.value === response.data.certificateType
        );
        const selectedFleet = Fleet.find(
          (a) => a.value === response.data.fleet
        );
        const selectedMaterial = RawMaterial.find(
          (a) => a.value === response.data.rawMaterialType
        );

        formik.setValues({
          ...response.data,
          fleet: selectedFleet,
          certificateType: selectedCertificate,
          rawMaterialType: selectedMaterial,
        });
      });
    }
  }, [uuid, Action.VIEW]);

  const component = () => {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Delete Vendor</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">Are you sure delete this Vendor?</div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsShowModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteData()}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="col-lg-6">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Add New Vendor</h5>
          <form>
            <Input
              type="text"
              label="Name"
              name="supplierName"
              value={formik.values.supplierName}
              onChange={formik.handleChange}
              placeholder="Input Supplier Name"
              errorMessage={formik.errors?.supplierName}
              isError={
                formik.errors.supplierName && formik.touched.supplierName
              }
            />
            <Input
              type="text"
              label="Code"
              name="vendorCode"
              value={formik.values.vendorCode}
              onChange={formik.handleChange}
              placeholder="Input Vendor"
              errorMessage={formik.errors?.vendorCode}
              isError={formik.errors.vendorCode && formik.touched.vendorCode}
            />
            <Input
              type="select"
              label="Certificate"
              name="certificateType"
              inputLabel="Select Certificate"
              value={formik.values.certificateType}
              onChange={formik.handleChange}
              options={Certificate}
              errorMessage={formik.errors?.certificateType}
              isError={
                formik.errors.certificateType && formik.touched.certificateType
              }
            />
            <Input
              type="select"
              label="Fleet"
              name="fleet"
              inputLabel="Select Fleet"
              value={formik.values.fleet}
              onChange={formik.handleChange}
              options={Fleet}
              errorMessage={formik.errors?.fleet}
              isError={formik.errors.fleet && formik.touched.fleet}
            />
            <Input
              type="text"
              label="Fisherman Group"
              name="owner"
              value={formik.values.owner}
              onChange={formik.handleChange}
              placeholder="Input Fisherman Group"
              errorMessage={formik.errors?.owner}
              isError={formik.errors.owner && formik.touched.owner}
            />
            <Input
              type="select"
              inputLabel="Raw Material"
              label="Material"
              name="rawMaterialType"
              value={formik.values.rawMaterialType}
              onChange={formik.handleChange}
              placeholder="Input Grade"
              options={RawMaterial}
              errorMessage={formik.errors?.rawMaterialType}
              isError={
                formik.errors.rawMaterialType && formik.touched.rawMaterialType
              }
            />
            <div className="row mb-3">
              <div className=" d-flex justify-content-end">
                <button
                  className="btn btn-secondary"
                  onClick={() => navigate("/vendor")}
                >
                  Close
                </button>
                {action === Action.VIEW && (
                  <Button
                    type={"danger"}
                    className="ms-2"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowModal(true);
                    }}
                    toggle="modal"
                    target="#verticalycentered"
                    label={"Delete"}
                  />
                )}
                <button
                  type="submit"
                  className="ms-2 btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  {action === Action.CREATE ? "Create" : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ModalPopUp
        isOpen={isShowModal}
        component={component}
        setIsOpen={setIsShowModal}
      />
    </div>
  );
}
