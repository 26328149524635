import { _Get, _Post, _Patch, _Delete } from "./base";
import Cookies from "js-cookie";

const endpoint = "mtm/trimming";

const PagedSearchTrimming = (code) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/search/${code}`, {
    ..._options,
  });
};

const CreateNewTrimming = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Post(`${process.env.REACT_APP_API_URL}/${endpoint}`, data, _options);
};

const ReadTrimmingByUuid = (uuid) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`, {
    ..._options,
  });
};

const UpdateTrimming = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Patch(`${process.env.REACT_APP_API_URL}/${endpoint}`, data, _options);
};

const DeleteReceivingTrimming = (uuid) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Delete(
    `${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`,
    _options
  );
};

const ExportDailyTrimming = (date) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/dailyreport`, {
    params: {
      ...date,
    },
    ..._options,
  });
};

const TrimmingGroupByMonth = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/groupbydate`, {
    params: {
      ...data,
    },
    ..._options,
  });
};

const TrimmingSearchByYearandMonth = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(
    `${process.env.REACT_APP_API_URL}/${endpoint}/groupbydate/${data.year}/${data.month}`,
    {
      params: {
        ...data,
      },
      ..._options,
    }
  );
};

const TrimmingSearchByDate = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(
    `${process.env.REACT_APP_API_URL}/${endpoint}/groupbydate/${data.date}`,
    {
      params: {
        ...data,
      },
      ..._options,
    }
  );
};

const TrimmingDetail = (data) => {
  let accessToken = Cookies.get("accessToken");
  let _options = {
    headers: {
      contentType: "application/json",
      authorization: `bearer ${accessToken}`,
    },
    withCredentials: true,
  };
  return _Get(
    `${process.env.REACT_APP_API_URL}/${endpoint}/groupbydate/detail/${data.date}/${data.vendorCode}`,
    {
      params: {
        ...data,
      },
      ..._options,
    }
  );
};

export {
  CreateNewTrimming,
  ReadTrimmingByUuid,
  UpdateTrimming,
  DeleteReceivingTrimming,
  PagedSearchTrimming,
  ExportDailyTrimming,
  TrimmingGroupByMonth,
  TrimmingSearchByYearandMonth,
  TrimmingSearchByDate,
  TrimmingDetail,
};
