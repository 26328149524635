import React, { useState } from "react";
import { DataTable } from "../../Components";
import CSMRepack from "../../API/CSMRepack";
import { intToMonth } from "../../Utility";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import moment from "moment";
import { useModal } from "../../Hook/useModal";
import { ConfirmationModal } from "../../Components";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import Modal from "./Modal";

export default function DayTable({
  selectedDate,
  setDailyDate,
  setSelectedDate,
}) {
  const { lastDataModificationTimestamp, setToastInfo, setIsShowToast } =
    useApplicationStoreContext();
  const _csmrepack = new CSMRepack();
  const confirmationModal = useModal();
  const editModal = useModal();
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState(false);

  const exportPdf = (lot, sap, batch, type, superBatch) => {
    var doc = new jsPDF("p", "mm", [50, 50]);

    QRCode.toDataURL(`${lot}\t${sap}\t${batch}\t${type}\t${superBatch}`, {
      margin: 1,
    })
      .then((lotCode) => {
        doc.addImage(lotCode, "JPEG", 5, 33, 14, 14);
        doc.setFontSize(11);
        doc.text(lot, 12, 30, null, 90);

        doc.addImage(lotCode, "JPEG", 32, 33, 14, 14);
        doc.setFontSize(11);
        doc.text(lot, 40, 30, null, 90);
        doc.autoPrint();
        var iframe = document.createElement("iframe");
        iframe.src = doc.output("datauristring");
        iframe.style.width = "0";
        iframe.style.height = "0";
        document.body.appendChild(iframe);
      })
      .catch((error) => console.log(error));
  };

  const tableHeadersCSM = [
    {
      name: "Item",
    },
    {
      name: "Qty",
    },
    {
      name: "Batch",
    },
    {
      name: "Super Batch",
    },
    {
      name: "Lot",
    },
    {
      name: "Item",
    },
    {
      name: "Qty",
    },
    {
      name: "Batch",
    },
    {
      name: "Super Batch",
    },
    {
      name: "Lot",
    },
    {
      name: "Action",
    },
  ];

  const tableBodyCSM = [
    {
      name: "sourceItem",
    },
    {
      name: "sourceQty",
    },
    {
      name: "sourceBatch",
    },
    {
      name: "sourceSuperBatch",
    },
    {
      name: "source_combined_lot",
    },
    {
      name: "destinationItem",
    },
    {
      name: "destinationQty",
    },
    {
      name: "destinationBatch",
    },
    {
      name: "destinationSuperBatch",
    },
    {
      name: "destination_combined_lot",
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                editModal.toggleModal();
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-info text-white py-0 px-1 me-2"
              onClick={() => {
                exportPdf(
                  data.destination_combined_lot,
                  data.destinationItem,
                  data.destinationBatch,
                  parseInt(data.destination_combined_lot[0]) ? "B" : "A",
                  data.destinationSuperBatch
                );
              }}
            >
              <i className="bi bi-printer"></i>
            </button>

            <button
              className="btn btn-success text-white py-0 px-1 me-2"
              onClick={() => {
                var textToCopy = `${data.destination_combined_lot}\t${
                  data.destinationItem
                }\t${data.destinationBatch}\t${
                  parseInt(data.destination_combined_lot[0]) ? "B" : "A"
                }\t${data.destinationSuperBatch}`;

                // Copy the text to the clipboard
                navigator.clipboard
                  .writeText(textToCopy)
                  .then(() => {
                    // Inform the user
                    alert("Text has been copied: " + textToCopy);
                  })
                  .catch((err) => {
                    console.error("Could not copy text: ", err);
                  });
              }}
            >
              <i className="bi bi-c-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1 me-2"
              onClick={() => {
                setSelected(data);
                confirmationModal.toggleModal();
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  const handleDelete = () => {
    _csmrepack
      .DeleteRepack(selected.uuid)
      .then(() => {
        setToastInfo({
          message: "packing successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setRefresh((prev) => !prev);
      })
      .catch(() => {
        setToastInfo({
          message: "packing failed to delet",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  return (
    <div className={`col-lg-12`}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">
              {intToMonth(selectedDate.month)} {selectedDate.year}
            </h5>
            <div className="row align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();

                  setDailyDate();
                  setSelectedDate();
                }}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Before Repack</th>
                <th>After Repack</th>
              </tr>
            </thead>
          </table>
          <DataTable
            api={_csmrepack.SearchByRepackDate}
            params={{
              date: moment(selectedDate).format("YYYY-MM-DD"),
              keyword: "",
            }}
            tableHeader={tableHeadersCSM}
            tableBody={tableBodyCSM}
            dependencies={[
              selectedDate,
              lastDataModificationTimestamp,
              refresh,
            ]}
            activeClassName={(item) =>
              item.lastModifiedDateTime && "text-danger"
            }
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={confirmationModal.modal}
        toggle={confirmationModal.toggleModal}
        onSubmit={handleDelete}
        message={"Are you sure delete this item?"}
      />
      <Modal
        show={editModal.modal}
        toggleModal={editModal.toggleModal}
        setRefresh={setRefresh}
        uuid={selected?.uuid}
      />
    </div>
  );
}
