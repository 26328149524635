import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  CreatePlant,
  ReadPlantByUuid,
  UpdatePlant,
  DeletePlant,
} from "../../API";
import { ModalPopUp, Input } from "../../Components";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { useFormik } from "formik";
import { Action } from "../../Constant";
import * as Yup from "yup";

export default function PlantForm() {
  const {
    isShowModal,
    setIsShowModal,
    setLastDataModificationTimestamp,
    setToastInfo,
    setIsShowToast,
  } = useApplicationStoreContext();

  const navigate = useNavigate();
  const { uuid } = useParams();
  const [action, setAction] = useState(Action.CREATE);

  const formik = useFormik({
    initialValues: {
      name: "",
      location: "",
      plantCode: "",
      batchCode: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("name is required"),
      location: Yup.string().required("location is required"),
      plantCode: Yup.string().required("plant code is required"),
      batchCode: Yup.string().required("batch code is required"),
    }),
    onSubmit: () => {
      navigate("/plant");
      if (action === Action.CREATE) {
        CreatePlant(formik.values)
          .then((response) => {
            setToastInfo({
              message: "Plant successfully created",
              background: "success",
            });
            setIsShowToast(true);
            setLastDataModificationTimestamp(new Date().getTime());
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Plant failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        UpdatePlant(uuid, formik.values)
          .then((response) => {
            setToastInfo({
              message: "Plant successfully update",
              background: "success",
            });
            setIsShowToast(true);
            setLastDataModificationTimestamp(new Date().getTime());
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Plant failed update",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  const deleteData = () => {
    DeletePlant(uuid)
      .then(() => {
        setToastInfo({
          message: "Plant successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setIsShowModal(false);
        setLastDataModificationTimestamp(new Date().getTime());
        navigate("/plant");
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "Plant failed deleted",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  useEffect(() => {
    if (uuid) {
      setAction(Action.VIEW);
      ReadPlantByUuid(uuid).then((response) => formik.setValues(response.data));
    }
  }, [uuid]);

  const component = () => {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Delete Plant</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">Are you sure delete this plant?</div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsShowModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteData()}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="col-lg-6">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Add New Plant</h5>
          <form>
            <Input
              label="Name"
              type="text"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              placeholder="Name"
              errorMessage={formik.errors?.name}
              isError={formik.errors.name && formik.touched.name}
            />
            <Input
              label="Location"
              type="text"
              name="location"
              onChange={formik.handleChange}
              value={formik.values.location}
              placeholder="Location"
              errorMessage={formik.errors?.location}
              isError={formik.errors.location && formik.touched.location}
            />
            <Input
              label="Plant Code"
              type="text"
              name="plantCode"
              onChange={formik.handleChange}
              value={formik.values.plantCode}
              placeholder="Plant Code"
              errorMessage={formik.errors?.plantCode}
              isError={formik.errors.plantCode && formik.touched.plantCode}
            />
            <Input
              label="Batch Code"
              type="text"
              name="batchCode"
              onChange={formik.handleChange}
              value={formik.values.batchCode}
              placeholder="Batch Code"
              errorMessage={formik.errors?.batchCode}
              isError={formik.errors.batchCode && formik.touched.batchCode}
            />
            <div className="row mb-3">
              <div className=" d-flex justify-content-end">
                <button
                  className="btn btn-secondary"
                  onClick={() => navigate("/plant")}
                >
                  Close
                </button>
                {action === Action.VIEW && (
                  <button
                    className="ms-2 btn btn-danger"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowModal(true);
                    }}
                    data-bs-toggle="modal"
                    data-bs-target="#verticalycentered"
                  >
                    Delete
                  </button>
                )}
                <button
                  type="submit"
                  className="ms-2 btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  {action == Action.CREATE ? "Create" : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ModalPopUp
        component={component}
        isOpen={isShowModal}
        setIsOpen={setIsShowModal}
      />
    </div>
  );
}
