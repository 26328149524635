import React, { useRef, useState } from "react";
import { ReadQRCode, ExportDailyPacking } from "../../API";
import Yearly from "./Yearly";
import PackingList from "./PackingList";
import Monthly from "./Monthly";
import Detail from "./Detail";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { addLeadingZero } from "../../Utility";
import fileDownload from "js-file-download";

export default function PackingTable() {
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();

  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  const formattedDate = yyyy + "-" + mm + "-" + dd;

  const [packingList, setPackingList] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDetail, setSelectedDetail] = useState();
  const [date, setDate] = useState({ start: formattedDate, end: "" });
  const ref = useRef();

  const handleReadCode = (lotCode) => {
    if (lotCode.length !== 20) {
      return;
    }

    const isAlreadyAdded = packingList.filter(
      (item) => item.lotCode === lotCode
    );

    if (isAlreadyAdded.length > 0) {
      setToastInfo({
        message: `${lotCode} already exist`,
        background: "danger",
      });
      setIsShowToast(true);
      ref.current.value = "";
      return;
    }

    ReadQRCode(lotCode)
      .then((response) => {
        if (response.data.packingUuid) {
          const [day, month, year] = response.data.packingDate.split("-");
          setToastInfo({
            message: `Internal Lot Code already on MT-${
              year.substring(2) +
              addLeadingZero(month, 2) +
              "." +
              addLeadingZero(response.data.caseNo, 4)
            }`,
            background: "danger",
          });
          setIsShowToast(true);
          ref.current.value = "";
          return;
        }

        if (packingList.length === 0) {
          setPackingList([response.data, ...packingList]);
          ref.current.value = "";
          return;
        }

        const isMix = packingList.filter(
          (a) =>
            a.grade === response.data.grade && a.size === response.data.size
        );

        if (isMix.length > 0) {
          setPackingList([response.data, ...packingList]);
          ref.current.value = "";
        } else {
          setToastInfo({
            message: "Can't combine multiple grade, certificate, or size",
            background: "danger",
          });
          setIsShowToast(true);
          ref.current.value = "";
        }
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "Internal Lot Code incorrect",
          background: "danger",
        });
        setIsShowToast(true);
        ref.current.value = "";
      });
  };

  const handleExportDaily = () => {
    ExportDailyPacking(date)
      .then((response) => {
        const fileName = `packing(${date.start} - ${date.end}).csv`;
        fileDownload(response.data, fileName);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "data not found",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Packing</h1>
        <div className="d-flex justify-content-between">
          <div className="col-md-4 mt-3">
            <input
              ref={ref}
              type="text"
              className="form-control"
              onChange={(e) => handleReadCode(e.target.value)}
              placeholder="scan internal lot code"
              maxLength="20"
            />
          </div>
          <div className="col-sm-3 mt-3 d-flex">
            <input
              type="date"
              className="form-control me-1"
              value={date.start}
              onChange={(e) => setDate({ ...date, start: e.target.value })}
            />
            <input
              type="date"
              className="form-control me-1"
              value={date.end}
              onChange={(e) => setDate({ ...date, end: e.target.value })}
            />
            <button
              className="btn btn-primary me-1"
              onClick={handleExportDaily}
            >
              Download
            </button>
          </div>
        </div>
      </div>
      <section className="section">
        <div className="row">
          <PackingList
            packingList={packingList}
            setPackingList={setPackingList}
          />
          <Yearly
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            setSelectedDetail={setSelectedDetail}
          />
          <Monthly
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            setSelectedDetail={setSelectedDetail}
            selectedDetail={selectedDetail}
          />
          <Detail
            selectedDetail={selectedDetail}
            setSelectedDetail={setSelectedDetail}
            setSelectedDate={setSelectedDate}
          />
        </div>
      </section>
    </main>
  );
}
