import React, { useEffect, useRef, useState } from "react";
import { PackingDetail, UpdatePacking, ReadQRCode } from "../../API";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { handleSize, addLeadingZero } from "../../Utility";
import jsPDF from "jspdf";
import QRCode from "qrcode";
import { Loading } from "../../Components";

export default function Detail({
  selectedDetail,
  setSelectedDetail,
  setSelectedDate,
}) {
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const [packingList, setPackingList] = useState([]);
  const [packingDate, setPackingDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef();

  const getPacking = () => {
    setIsLoading(true);
    PackingDetail(selectedDetail.uuid).then((response) => {
      setPackingList(response.data.data);
      setIsLoading(false);
    });

    let dateStr = selectedDetail.packingDate;
    let dateArr = dateStr.split("-");
    dateArr.reverse();
    let newDateStr = dateArr.join("-");
    setPackingDate(newDateStr);
  };

  useEffect(() => {
    if (selectedDetail) {
      getPacking();
    }
  }, [selectedDetail]);

  const handleDelete = (data) => {
    const afterDeleted = packingList.filter((item) => item.uuid !== data.uuid);

    setPackingList([...afterDeleted]);
  };

  const totalWeight = () => {
    return packingList.reduce((sum, item) => sum + parseFloat(item.weight), 0);
  };

  const getNewestDate = () => {
    let newestDate = null;

    packingList.forEach((item) => {
      const [day, month, year] = item.trimmingDate.split("-");
      const trimmingDate = new Date(`${month}-${day}-${year}`);

      if (!isNaN(trimmingDate) && (!newestDate || trimmingDate > newestDate)) {
        newestDate = trimmingDate;
      }
    });

    return newestDate.toLocaleDateString("en-GB");
  };

  const exportPdf = () => {
    var doc = new jsPDF("p", "mm", [150, 100]);

    QRCode.toDataURL(selectedDetail.boxNo, { margin: 1 }).then((lotCode) => {
      doc.setFontSize(8);
      doc.setFont("helvetica", "bold");
      doc.text(`Case No`, 5, 10);
      doc.text(getMostProperty("certificateType"), 90, 10);

      doc.setFontSize(12);
      doc.text(selectedDetail.boxNo, 37, 10);
      doc.setFontSize(9);
      doc.text(`FROZEN YELLOWFIN TUNA LOIN`, 25, 20);
      doc.text(`(Thunus albacares)`, 35, 24);

      doc.setFontSize(8);
      doc.text(`Grade`, 5, 35);
      doc.text(getMostProperty("grade"), 5, 38);
      doc.text(`Size`, 30, 35);
      doc.text(handleSize(parseInt(getMostProperty("size"))), 30, 38);

      doc.text(`Net Weight`, 55, 35);
      doc.text(Number(totalWeight()).toFixed(2), 55, 38);
      doc.text(`Pieces`, 85, 35);
      doc.text(packingList.length.toString(), 85, 38);

      doc.addImage(lotCode, "JPEG", 45, 42, 50, 50);

      doc.text(`Fleet`, 5, 45);
      doc.text(getMostProperty("fleet"), 5, 48);

      const [day, month, year] = getNewestDate().split("/");
      const expireDate = `${day}/${month}/${parseInt(year) + 2}`;

      doc.text(`Expiry Date`, 5, 60);
      doc.text(expireDate, 5, 63);

      const productionDate = `${day}/${month}/${year}`;

      doc.text(`Production Date`, 5, 75);
      doc.text(productionDate, 5, 78);

      doc.text(`Processed By:`, 5, 100);
      doc.text(`CV. MITRA TUNA MANDIRI`, 5, 105);
      doc.text(`Kompleks PPN Ternate`, 5, 108);
      doc.text(`Bastiong Talangame, Ternate`, 5, 111);
      doc.text(`North Maluku, Indonesia`, 5, 114);

      doc.text(`Keep Frozen -18°C`, 5, 125);
      doc.text(`CONTAINS : Fish (Tuna)`, 5, 128);

      doc.autoPrint();
      var iframe = document.createElement("iframe");
      iframe.src = doc.output("datauristring");
      iframe.style.width = "0";
      iframe.style.height = "0";
      document.body.appendChild(iframe);
    });
  };

  const getMostProperty = (property) => {
    const propertyCounts = packingList.reduce((counts, item) => {
      counts[item[property]] = (counts[item[property]] || 0) + 1;
      return counts;
    }, {});

    const mostCommonProperty = Object.keys(propertyCounts).reduce((a, b) =>
      propertyCounts[a] > propertyCounts[b] ? a : b
    );

    return mostCommonProperty;
  };

  const handleUpdate = () => {
    UpdatePacking(selectedDetail.uuid, {
      caseNo: selectedDetail.caseNo,
      packingDate: packingDate,
      packingList: packingList,
    })
      .then((response) => {
        exportPdf();
        getPacking();
        setToastInfo({
          message: "packing successfully updated",
          background: "success",
        });
        setIsShowToast(true);
        setSelectedDetail();
        setSelectedDate();
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "packing failed updated",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const handleReadCode = (lotCode) => {
    if (lotCode.length !== 20) {
      return;
    }

    const isAlreadyAdded = packingList.filter(
      (item) => item.lotCode === lotCode
    );

    if (isAlreadyAdded.length > 0) {
      setToastInfo({
        message: `${lotCode} already exist`,
        background: "danger",
      });
      setIsShowToast(true);
      ref.current.value = "";
      return;
    }

    ReadQRCode(lotCode)
      .then((response) => {
        if (response.data.packingUuid) {
          const [day, month, year] = response.data.packingDate.split("-");
          setToastInfo({
            message: `Internal Lot Code already on MT-${
              year.substring(2) +
              addLeadingZero(month, 2) +
              "." +
              addLeadingZero(response.data.caseNo, 4)
            }`,
            background: "danger",
          });
          setIsShowToast(true);
          ref.current.value = "";
          return;
        }

        if (packingList.length === 0) {
          setPackingList([response.data, ...packingList]);
          ref.current.value = "";
          return;
        }

        const isMix = packingList.filter(
          (a) =>
            a.grade === response.data.grade && a.size === response.data.size
        );

        if (isMix.length > 0) {
          setPackingList([response.data, ...packingList]);
          ref.current.value = "";
        } else {
          setToastInfo({
            message: "Can't combine multiple grade, certificate, or size",
            background: "danger",
          });
          setIsShowToast(true);
          ref.current.value = "";
        }
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "Internal Lot Code incorrect",
          background: "danger",
        });
        setIsShowToast(true);
        ref.current.value = "";
      });
  };

  if (!selectedDetail) {
    return null;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">{selectedDetail.boxNo}</h5>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedDetail();
                }}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          </div>

          <div className="d-flex justify-content-between">
            <div className="col-xl-4">
              <input
                ref={ref}
                type="text"
                className="form-control"
                onChange={(e) => handleReadCode(e.target.value)}
                placeholder="scan internal lot code"
              />
            </div>
            <div className="d-flex align-items-center">
              <input
                type="date"
                className="form-control me-3"
                value={packingDate}
                onChange={(e) => setPackingDate(e.target.value)}
              />
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdate();
                }}
              >
                Update
              </button>
              {/* <button
                className="btn btn-secondary mx-2"
                onClick={() => {
                  exportPdf();
                }}
              >
                <i className="bi bi-printer"></i>
              </button> */}
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Internal Lot Code</th>
                <th scope="col">Certificate</th>
                <th scope="col">PDC</th>
                <th scope="col">Grade</th>
                <th scope="col">Size</th>
                <th scope="col">Fleet</th>
                <th scope="col">Weight</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {packingList.length > 0 &&
                packingList.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row">{item.lotCode}</th>
                      <td>{item.certificateType}</td>
                      <td>{item.trimmingDate}</td>
                      <td>{item.grade}</td>
                      <td>{handleSize(item.size)}</td>
                      <td>{item.fleet}</td>
                      <td>{item.weight}</td>
                      <td>
                        <button
                          className="btn btn-danger py-0 px-1"
                          onClick={() => handleDelete(item)}
                        >
                          <i className="bi bi-trash3"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
            {packingList && packingList.length > 0 && (
              <tfoot>
                <tr>
                  <td colSpan={5}>Total</td>
                  <td>{packingList.length} Pcs</td>
                  <td>{Number(totalWeight()).toFixed(2)} Kg</td>
                  <td></td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </div>
  );
}
