import React from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { useApplicationStoreContext } from "../Hook/UserHook";

export default function Toaster() {
  const { isShowToast, setIsShowToast, toastInfo } =
    useApplicationStoreContext();
  return (
    <ToastContainer position="top-end">
      <Toast
        show={isShowToast}
        onClose={() => setIsShowToast(false)}
        delay={5000}
        autohide
        bg={toastInfo?.background}
        className="sticky-bottom-toast"
      >
        <Toast.Body style={{ color: "white" }}>{toastInfo?.message}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
