import React, { useState } from "react";
import PDC from "./PDC";
import Supplier from "./Supplier";
import Detail from "./Detail";
import Monthly from "./Monthly";
import DetailCS from "./DetailCS";
import { useParams } from "react-router-dom";

export default function Index() {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedDetail, setSelectedDetail] = useState();
  const { inventory } = useParams();

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Inventory {inventory}</h1>
      </div>
      <section className="section">
        <div className="row">
          {inventory === "cs" ? (
            <>
              <Monthly
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                setSelectedDetail={setSelectedDetail}
                inventory={inventory}
              />
              <DetailCS
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                setSelectedDetail={setSelectedDetail}
                inventory={inventory}
              />
            </>
          ) : (
            <>
              <PDC
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                setSelectedDetail={setSelectedDetail}
                inventory={inventory}
              />
              <Supplier
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                setSelectedDetail={setSelectedDetail}
                selectedDetail={selectedDetail}
                inventory={inventory}
              />
              <Detail
                selectedDetail={selectedDetail}
                setSelectedDetail={setSelectedDetail}
                inventory={inventory}
              />
            </>
          )}
        </div>
      </section>
    </main>
  );
}
