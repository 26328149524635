import { _Delete, _Get, _Patch, _Post } from "./base";
import Cookies from "js-cookie";

const endpoint = "csm/inventory";

class CSMInventory {
  constructor() {}

  GetPDC = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/${params.inventory}`,
      {
        params: {
          ...params,
        },
        ..._options,
      }
    );
  };

  GetSearchByLot = (inventory, lot) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/${inventory}/search/${lot}`,
      {
        ..._options,
      }
    );
  };

  ExportInventoryDaily = (category) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/report/${category}`,
      {
        ..._options,
      }
    );
  };

  // cs indo

  IndoInventoryGroup = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/cs/indo`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  IndoInventoryDetail = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/cs/indo/detail`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  DeleteIndoInventoryDetail = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(
      `${process.env.REACT_APP_API_URL}/${endpoint}/cs/indo/detail/${uuid}`,
      {
        ..._options,
      }
    );
  };

  UpdateIndoInventoryDetail = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Patch(
      `${process.env.REACT_APP_API_URL}/${endpoint}/cs/indo/detail/${data.uuid}`,
      data,
      {
        ..._options,
      }
    );
  };

  UploadIndoInventory = (data) => {
    let fileData = new FormData();
    fileData.append("excel", data);

    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "multipart/form-data",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}/import`,
      fileData,
      _options
    );
  };

  ExportIndoInventory = () => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/cs/indo/report`, {
      ..._options,
    });
  };

  ExportIndoInventoryDetail = () => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/cs/indo/report/detail`,
      {
        ..._options,
      }
    );
  };

  SummarizeIndoByVendor = (vendor) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/cs/indo/${vendor}`,
      {
        ..._options,
      }
    );
  };

  // cs FG

  GetInventoryCSMPacking2 = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/csm/packing2`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  GetInventoryCSMPacking2Detail = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/csm/packing2/${params.batch}`,
      {
        params: {
          ...params,
        },
        ..._options,
      }
    );
  };

  GetInventoryCSMPacking2DetailSAP = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/csm/packing2/${params.batch}/${params.sap}`,
      {
        params: {
          ...params,
        },
        ..._options,
      }
    );
  };

  GetInventoryCSMPacking2DetailReport = (batch) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/csm/packing2/report/${batch}`,
      {
        ..._options,
      }
    );
  };
}

export default CSMInventory;
