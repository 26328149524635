import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Action } from "../../Constant";
import * as Yup from "yup";
import { ModalPopUp, Input, Button } from "../../Components";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { Origin, MaterialType } from "../../Constant";
import CSMVendor from "../../API/CSMvendor";
import moment from "moment";

export default function VendorForm() {
  const _CSMVendor = new CSMVendor();

  const {
    setIsShowModal,
    setLastDataModificationTimestamp,
    setToastInfo,
    setIsShowToast,
    isShowModal,
  } = useApplicationStoreContext();
  const navigate = useNavigate();
  const { uuid } = useParams();

  const [action, setAction] = useState(Action.CREATE);

  const formik = useFormik({
    initialValues: {
      rmLotCode: "",
      reff: "",
      materialType: "",
      vesselName: "",
      origin: "",
      tripDate: null,
      invoice: "",
    },
    validationSchema: Yup.object({
      rmLotCode: Yup.string().required("lot code is required"),
      reff: Yup.string().required("reff is required"),
      materialType: Yup.object().required("Material type required"),
      vesselName: Yup.string().required("vessel name is required"),
      origin: Yup.object().required("Origin required"),
      invoice: Yup.string().required("invoice is required"),
    }),
    onSubmit: (values) => {
      const updated = {
        ...formik.values,
        materialType: formik.values.materialType.value,
        origin: formik.values.origin.value,
      };
      if (action === Action.CREATE) {
        _CSMVendor
          .CreateVendor(updated)
          .then((response) => {
            setToastInfo({
              message: "vendor successfully created",
              background: "success",
            });
            setIsShowToast(true);
            setLastDataModificationTimestamp(new Date().getTime());
            navigate("/vendor");
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "vendor failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        _CSMVendor
          .UpdateVendor(uuid, updated)
          .then((response) => {
            setToastInfo({
              message: "vendor successfully updated",
              background: "success",
            });
            setIsShowToast(true);
            setLastDataModificationTimestamp(new Date().getTime());
            navigate("/vendor");
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "vendor failed updated",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  const deleteData = () => {
    _CSMVendor
      .DeleteVendor(uuid)
      .then(() => {
        setToastInfo({
          message: "vendor successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setIsShowModal(false);
        setLastDataModificationTimestamp(new Date().getTime());
        navigate("/vendor");
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "vendor failed deleted",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  useEffect(() => {
    if (uuid) {
      setAction(Action.VIEW);
      _CSMVendor.ReadVendorByUuid(uuid).then((response) => {
        const seletedMaterial = MaterialType.find(
          (a) => a.value === response.data.materialType
        );
        const selectedOrigin = Origin.find(
          (a) => a.value === response.data.origin
        );
        formik.setValues({
          ...response.data,
          materialType: seletedMaterial,
          origin: selectedOrigin,
          tripDate: moment(response.data.tripDate).format("YYYY-MM-DD"),
          returnDate: moment(response.data.returnDate).format("YYYY-MM-DD"),
        });
      });
    }
  }, [uuid, Action.VIEW]);

  const component = () => {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Delete Vendor</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">Are you sure delete this vendor?</div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsShowModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteData()}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="col-lg-6">
      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Add New Vendor</h5>
          <form>
            <Input
              type="text"
              label="CTP Reff#"
              name="rmLotCode"
              value={formik.values.rmLotCode}
              onChange={formik.handleChange}
              placeholder="Input CTP Reff#"
              errorMessage={formik.errors?.rmLotCode}
              isError={formik.errors.rmLotCode && formik.touched.rmLotCode}
            />
            <Input
              type="text"
              label="CSM Reff"
              name="reff"
              value={formik.values.reff}
              onChange={formik.handleChange}
              placeholder="Input CSM Reff"
              errorMessage={formik.errors?.reff}
              isError={formik.errors.reff && formik.touched.reff}
            />
            <Input
              type="select"
              label="Material Type"
              name="materialType"
              inputLabel="Select Meterial Type"
              value={formik.values.materialType}
              onChange={formik.handleChange}
              options={MaterialType}
              errorMessage={formik.errors?.materialType}
              isError={
                formik.errors.materialType && formik.touched.materialType
              }
            />
            <Input
              type="text"
              label="Vessel Name"
              name="vesselName"
              value={formik.values.vesselName}
              onChange={formik.handleChange}
              placeholder="Input Vessel Name"
              errorMessage={formik.errors?.vesselName}
              isError={formik.errors.vesselName && formik.touched.vesselName}
            />
            <Input
              type="select"
              label="Origin"
              name="origin"
              inputLabel="Select Origin"
              value={formik.values.origin}
              onChange={formik.handleChange}
              options={Origin}
              errorMessage={formik.errors?.origin}
              isError={formik.errors.origin && formik.touched.origin}
            />
            <Input
              type="date"
              label="Trip Date"
              name="tripDate"
              value={formik.values.tripDate}
              onChange={formik.handleChange}
              errorMessage={formik.errors?.tripDate}
              isError={formik.errors.tripDate && formik.touched.tripDate}
            />
            <Input
              type="date"
              label="Return Date"
              name="returnDate"
              value={formik.values.returnDate}
              onChange={formik.handleChange}
              errorMessage={formik.errors?.returnDate}
              isError={formik.errors.returnDate && formik.touched.returnDate}
            />
            <Input
              type="text"
              label="Invoice"
              name="invoice"
              value={formik.values.invoice}
              onChange={formik.handleChange}
              placeholder="Input Invoice"
              errorMessage={formik.errors?.invoice}
              isError={formik.errors.invoice && formik.touched.invoice}
            />
            <div className="row mb-3">
              <div className=" d-flex justify-content-end">
                <button
                  className="btn btn-secondary"
                  onClick={() => navigate("/vendor")}
                >
                  Close
                </button>
                {action === Action.VIEW && (
                  <Button
                    type={"danger"}
                    className="ms-2"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowModal(true);
                    }}
                    toggle="modal"
                    target="#verticalycentered"
                    label={"Delete"}
                  />
                )}
                <button
                  type="submit"
                  className="ms-2 btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  {action === Action.CREATE ? "Create" : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ModalPopUp
        isOpen={isShowModal}
        component={component}
        setIsOpen={setIsShowModal}
      />
    </div>
  );
}
