import React, { useState } from "react";
import { DataTable, ModalPopUp } from "../../Components";
import CSMReceiving from "../../API/CSMReceiving";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import CreateForm from "./CreateForm";
import { Action } from "../../Constant";

export default function SupplierTable({
  setVendor,
  setDailyDate,
  dailyDate,
  vendor,
  selectedDate,
}) {
  const {
    lastDataModificationTimestamp,
    setToastInfo,
    setIsShowToast,
    setLastDataModificationTimestamp,
  } = useApplicationStoreContext();
  const _CSMReceiving = new CSMReceiving();
  const [isShowModal, setIsShowModal] = useState(false);
  const [selectedReceiving, setSelectedReceiving] = useState();
  const [action, setAction] = useState(Action.EDIT);

  const tableHeadersCSM = [
    {
      name: "RM Lot Code",
    },
    {
      name: "Reff",
    },
    {
      name: "Action",
    },
  ];

  const tableBodyCSM = [
    {
      name: "rmLotCode",
      view: (data) => {
        return (
          <a
            href={`/view/${data.uuid}`}
            onClick={(e) => {
              e.preventDefault();
              setVendor(data);
            }}
          >
            {data.rmLotCode}
          </a>
        );
      },
    },
    {
      name: "reff",
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              data-bs-toggle="modal"
              data-bs-target="#verticalycentered"
              onClick={() => {
                setAction(Action.EDIT);
                setIsShowModal(true);
                setSelectedReceiving(data);
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1"
              data-bs-toggle="modal"
              data-bs-target="#verticalycentered"
              onClick={() => {
                setAction(Action.DELETE);
                setIsShowModal(true);
                setSelectedReceiving(data);
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  const component = () => {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Create Receiving</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsShowModal(false)}
          />
        </div>
        <div className="modal-body">
          <CreateForm
            setIsOpen={setIsShowModal}
            uuid={selectedReceiving?.uuid}
          />
        </div>
      </div>
    );
  };

  const deleteData = () => {
    _CSMReceiving
      .DeleteReceiving(selectedReceiving.uuid)
      .then(() => {
        setToastInfo({
          message: "Receiving successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setIsShowModal(false);
        setLastDataModificationTimestamp(new Date());
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response?.status === 403
              ? err.response?.data?.message
              : "Receiving failed deleted",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const componentDelete = () => {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Delete Receiving</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">{`Are you sure delete ${selectedReceiving.rmLotCode} ?`}</div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsShowModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteData()}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`col-lg-6`}>
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between">
              <h5 className="card-title">{dailyDate}</h5>
              <div className="row align-items-center">
                <button
                  className="btn btn-danger"
                  onClick={(e) => {
                    e.preventDefault();
                    setVendor();
                    setDailyDate();
                  }}
                >
                  <i className="bi bi-x-lg"></i>
                </button>
              </div>
            </div>
            <DataTable
              api={_CSMReceiving.ReadReceivingByVendor}
              params={{
                date: dailyDate,
              }}
              tableHeader={tableHeadersCSM}
              tableBody={tableBodyCSM}
              dependencies={[
                dailyDate,
                lastDataModificationTimestamp,
                selectedDate,
              ]}
              activeClassName={(item) => {
                if (item?.uuid === vendor?.uuid) {
                  return "active";
                }
              }}
            />
          </div>
        </div>
      </div>
      <ModalPopUp
        component={action === Action.EDIT ? component : componentDelete}
        isOpen={isShowModal}
        setIsOpen={setIsShowModal}
      />
    </>
  );
}
