import React from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Input } from "../../Components";
import CSMInventory from "../../API/CSMInventory";
import { useApplicationStoreContext } from "../../Hook/UserHook";

export default function UpdateForm({ isOpen, toggle, data, setRefresh }) {
  const { setToastInfo, setIsShowToast } = useApplicationStoreContext();
  const _inventory = new CSMInventory();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: Yup.object({
      caseNumber: Yup.string().required("Case Number is required"),
      certificate: Yup.string().required("Certificate is required"),
      fleet: Yup.string().required("Fleet is required"),
      grade: Yup.string().required("Grade is required"),
      boxWeight: Yup.number().required("Weight is required"),
    }),
    onSubmit: (values) => {
      _inventory
        .UpdateIndoInventoryDetail(values)
        .then((response) => {
          setToastInfo({
            message: "successfully updated",
            background: "success",
          });
          setIsShowToast(true);
          setRefresh((prev) => !prev);
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response.status === 403
                ? err.response?.data?.message
                : "data not found",
            background: "danger",
          });
          setIsShowToast(true);
        });
    },
  });

  return (
    <Modal show={isOpen} onHide={toggle}>
      <ModalHeader>Update Form</ModalHeader>
      <ModalBody>
        <Input
          label="Case Number"
          type="text"
          name="caseNumber"
          onChange={formik.handleChange}
          value={formik.values?.caseNumber}
          errorMessage={formik.errors?.caseNumber}
          isError={formik.errors.caseNumber && formik.touched.caseNumber}
        />
        <Input
          label="certificate"
          type="text"
          name="certificate"
          onChange={formik.handleChange}
          value={formik.values?.certificate}
          errorMessage={formik.errors?.certificate}
          isError={formik.errors.certificate && formik.touched.certificate}
        />
        <Input
          label="Fleet"
          type="text"
          name="fleet"
          onChange={formik.handleChange}
          value={formik.values?.fleet}
          errorMessage={formik.errors?.fleet}
          isError={formik.errors.fleet && formik.touched.fleet}
        />
        <Input
          label="Grade"
          type="text"
          name="grade"
          onChange={formik.handleChange}
          value={formik.values?.grade}
          errorMessage={formik.errors?.grade}
          isError={formik.errors.grade && formik.touched.grade}
        />
        <Input
          label="Weight"
          type="number"
          name="boxWeight"
          onChange={formik.handleChange}
          value={formik.values?.boxWeight}
          errorMessage={formik.errors?.boxWeight}
          isError={formik.errors.boxWeight && formik.touched.boxWeight}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant="secondary" onClick={toggle}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            formik.handleSubmit();
            toggle();
          }}
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
}
