import { _Delete, _Get, _Patch, _Post } from "./base";
import Cookies from "js-cookie";

const endpoint = "csm/retouching";

class CSMRetouching {
  constructor() {}

  ReadRetouchingAll = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/all`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  ReadRetouchingByMonth = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/monthly`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  CreateNewRetouching = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      data,
      _options
    );
  };

  ReadRetouchingByUuid = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`, {
      ..._options,
    });
  };

  UpdateRetouching = (uuid, data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Patch(
      `${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`,
      data,
      _options
    );
  };

  DeleteRetouching = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(
      `${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`,
      _options
    );
  };

  ReadRetouchingByVendor = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/vendor`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  ReadRetouchingByRetouchingDate = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/daily`, {
      params: {
        ...data,
      },
      ..._options,
    });
  };

  ReadRetouchingDetail = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/detail`, {
      params: {
        ...data,
      },
      ..._options,
    });
  };

  ReadRetouchingTotalCountDaily = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/totalcount`, {
      params: {
        ...data,
      },
      ..._options,
    });
  };

  ExportRetouchingDaily = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/report`, {
      params: {
        ...data,
      },
      ..._options,
    });
  };

  SummarizeDetail = (uuid, date) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/summarize/${uuid}/${date}`,
      {
        ..._options,
      }
    );
  };
}

export default CSMRetouching;
