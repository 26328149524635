import React, { useState } from "react";
import CuttingModel from "../../API/CSMCutting";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { DataTable } from "../../Components";
import { ConfirmationModal } from "../../Components";
import { useModal } from "../../Hook/useModal";

export default function Detail({ selectedPT, selectedDate, setSelectedDate }) {
  const _cutting = new CuttingModel();
  const [selected, setSelected] = useState();
  const [refresh, setRefresh] = useState();
  const { setIsShowToast, setToastInfo } = useApplicationStoreContext();
  const { modal, toggleModal } = useModal();

  const handleDelete = () => {
    if (selectedPT.vendor === "ID") {
      _cutting
        .DeleteCuttingDetailIndo(selected.uuid)
        .then(() => {
          setToastInfo({
            message: "item successfull deleted",
            background: "success",
          });
          setIsShowToast(true);
          setRefresh((prev) => !prev);
        })
        .catch(() => {
          setToastInfo({
            message: "item failed to delete",
            background: "danger",
          });
          setIsShowToast(true);
        });

      return;
    }

    _cutting
      .DeleteCuttingDetailCSM(selected.uuid)
      .then(() => {
        setToastInfo({
          message: "item successfull deleted",
          background: "success",
        });
        setIsShowToast(true);
        setRefresh((prev) => !prev);
      })
      .catch(() => {
        setToastInfo({
          message: "item failed to delete",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const tableHeader = [
    {
      name: "Sack No",
    },
    {
      name: "Lot",
    },
    {
      name: "Packing Date",
    },
    {
      name: "PDC",
    },
    {
      name: "Origin",
    },
    {
      name: "Weight",
    },
    {
      name: "Action",
    },
  ];

  const tableBody = [
    {
      name: "sackNo",
    },
    {
      name: "lot",
    },
    {
      name: "packingDate",
    },
    {
      name: "receivingDate",
    },
    {
      name: "origin",
    },
    {
      name: "totalWeight",
    },
    {
      view: (data) => {
        return (
          <button
            className="btn btn-danger py-0 px-1"
            onClick={() => {
              setSelected(data);
              toggleModal();
            }}
          >
            <i className="bi bi-trash3"></i>
          </button>
        );
      },
    },
  ];

  const tableHeaderIndo = [
    {
      name: "Case No",
    },
    {
      name: "Source",
    },
    {
      name: "Certificate",
    },
    {
      name: "Grade",
    },
    {
      name: "Fleet",
    },
    {
      name: "Weight",
    },
    {
      name: "Code",
    },
    {
      name: "Action",
    },
  ];

  const tableBodyIndo = [
    {
      name: "caseNumber",
    },
    {
      name: "vendor",
    },
    {
      name: "certificate",
    },
    {
      name: "grade",
    },
    {
      name: "fleet",
    },
    {
      name: "boxWeight",
    },
    {
      name: "code",
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-danger py-0 px-1"
              onClick={() => {
                setSelected(data);
                toggleModal();
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  const da = selectedDate.split("-");

  if (!selectedPT) {
    return null;
  }

  return (
    <div className="col-lg-12">
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">Detail</h5>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedDate();
                }}
              >
                <i className="bi bi-x"></i>
              </button>
            </div>
          </div>
          <DataTable
            api={
              selectedPT.vendor == "ID"
                ? _cutting.CuttingDetailIndo
                : _cutting.CuttingDetail
            }
            params={{
              uuid: selectedPT.uuid,
              cuttingDate: `${da[2]}-${da[1]}-${da[0]}`,
            }}
            tableHeader={
              selectedPT.vendor == "ID" ? tableHeaderIndo : tableHeader
            }
            tableBody={selectedPT.vendor == "ID" ? tableBodyIndo : tableBody}
            dependencies={[selectedPT.uuid, selectedDate, refresh]}
          />
        </div>
      </div>
      <ConfirmationModal
        isOpen={modal}
        toggle={toggleModal}
        message={`are you sure want to delete?`}
        onSubmit={handleDelete}
      />
    </div>
  );
}
