import React from "react";
import { PagedSearchPlants } from "../../API";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import DataTable from "../../Components/DataTable";

export default function PlantTable() {
  const navigate = useNavigate();

  const tableHeaders = [
    {
      name: "Name",
    },
    {
      name: "Location",
    },
    {
      name: "Batch Code",
    },
  ];

  const tableBody = [
    {
      name: "name",
      view: (data) => {
        return (
          <a
            href={`view/${data.uuid}`}
            onClick={(e) => {
              e.preventDefault();
              navigate(`view/${data.uuid}`);
            }}
          >
            {data.name}
          </a>
        );
      },
    },
    {
      name: "location",
    },
    {
      name: "batchCode",
    },
  ];

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Plant</h1>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <h5 className="card-title">Plant List</h5>
                  <div className="row align-items-center">
                    <button
                      className="btn btn-primary"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("add");
                      }}
                    >
                      Create New
                    </button>
                  </div>
                </div>
                <DataTable
                  api={PagedSearchPlants}
                  tableHeader={tableHeaders}
                  tableBody={tableBody}
                />
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </section>
    </main>
  );
}
