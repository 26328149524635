import React, { useEffect, useState } from "react";
import CSMReceiving from "../../API/CSMReceiving";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { Input } from "../../Components";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Action } from "../../Constant";
import { handleSizeCSMReceiving } from "../../Utility";

export default function DetailForm({
  uuid,
  setIsOpen,
  CSMReceivingUuid,
  setLastDataModificationTimestamp,
}) {
  const { setToastInfo, setIsShowToast } = useApplicationStoreContext();
  const _CSMReceiving = new CSMReceiving();

  const [action, setAction] = useState(Action.CREATE);
  const [totalCountDailyProduction, setTotalCountDailyProduction] = useState(0);

  const formik = useFormik({
    initialValues: {
      fishStatus: "",
      weight: "",
    },
    validationSchema: Yup.object({
      fishStatus: Yup.object().required("fish status is required"),
      weight: Yup.number().required("weight is required"),
    }),
    onSubmit: () => {
      if (action === Action.CREATE) {
        const data = {
          ...formik.values,
          fishStatus: formik.values.fishStatus.value,
          CSMReceivingUuid,
        };
        _CSMReceiving
          .CreateNewReceivingDetail(data)
          .then(() => {
            setToastInfo({
              message: "Receiving successfully created",
              background: "success",
            });
            setIsShowToast(true);
            handleReadTotalCount();
            setLastDataModificationTimestamp(new Date());
            formik.setFieldValue("weight", "");
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Receiving failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        const data = {
          ...formik.values,
          fishStatus: formik.values.fishStatus.value,
        };
        _CSMReceiving
          .UpdateReceivingDetail(uuid, data)
          .then((response) => {
            setToastInfo({
              message: "Receiving successfully updated",
              background: "success",
            });
            setIsShowToast(true);
            setIsOpen(false);
            setLastDataModificationTimestamp(new Date());
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "Receiving failed updated",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  useEffect(() => {
    if (uuid) {
      setAction(Action.VIEW);
      _CSMReceiving
        .ReadReceivingDetailByUuid(uuid)
        .then((response) => {
          if (response.data.fishStatus) {
            formik.setValues({
              ...response.data,
              fishStatus: {
                value: 1,
                label: "GOOD",
              },
            });
          } else {
            formik.setValues({
              ...response.data,
              fishStatus: {
                value: 0,
                label: "REJECTED",
              },
            });
          }
        })
        .catch((err) => {
          setToastInfo({
            message:
              err.response?.status === 403
                ? err.response?.data?.message
                : "failed to get server",
            background: "danger",
          });
          setIsShowToast(true);
        });
    }
  }, [uuid]);

  const handleReadTotalCount = () => {
    _CSMReceiving
      .ReadReceivingTotalCountDaily({
        receivingUuid: CSMReceivingUuid,
      })
      .then((response) => {
        setTotalCountDailyProduction(response.data.totalCount);
      });
  };

  useEffect(() => {
    if (CSMReceivingUuid) {
      handleReadTotalCount();
    }
  }, [CSMReceivingUuid]);

  return (
    <form>
      <div className="d-flex gap-3">
        <h6>Total Count Daily Production</h6>
        <p>{totalCountDailyProduction}</p>
      </div>
      <Input
        label="Weight"
        type="number"
        name="weight"
        onChange={formik.handleChange}
        value={formik.values.weight}
        placeholder="Input Weight"
        errorMessage={formik.errors?.weight}
        isError={formik.errors.weight && formik.touched.weight}
        onKeyDown={(e) => e.key === "Enter" && formik.handleSubmit()}
      />
      <Input
        label="Size"
        type="text"
        name="size"
        value={handleSizeCSMReceiving(formik.values.weight)}
        disabled={true}
      />
      <Input
        label="Status"
        type="select"
        name="fishStatus"
        inputLabel="Select Fish Status"
        onChange={formik.handleChange}
        value={formik.values.fishStatus}
        options={[
          { value: 1, label: "GOOD" },
          { value: 0, label: "RECEJTED" },
        ]}
        errorMessage={formik.errors?.fishStatus}
        isError={formik.errors.fishStatus && formik.touched.fishStatus}
      />

      <div className="row mb-3">
        <div className=" d-flex justify-content-end">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(false);
            }}
          >
            Close
          </button>
          <button
            type="button"
            className="ms-2 btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              formik.handleSubmit();
            }}
          >
            {action === Action.CREATE ? "Create" : "Update"}
          </button>
        </div>
      </div>
    </form>
  );
}
