import React, { useState } from "react";
import CSMShipment from "../../API/CSMShipment";
import { DataTable, ConfirmationModal, Input } from "../../Components";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { useModal } from "../../Hook/useModal";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import CSMPacking2 from "../../API/CSMPacking2";

export default function Plan({ setPlan, uuid }) {
  const _csmshipment = new CSMShipment();
  const _csmpacking = new CSMPacking2();
  const { lastDataModificationTimestamp, setToastInfo, setIsShowToast } =
    useApplicationStoreContext();
  const createModal = useModal();
  const confirmationModal = useModal();
  const [refresh, setRefresh] = useState(false);

  const formik = useFormik({
    initialValues: {
      sap: "",
      batch: "",
      superBatch: "",
      qty: "",
    },
    validationSchema: Yup.object({
      sap: Yup.object().required("Item is required"),
      batch: Yup.string().required("Batch is required"),
      superBatch: Yup.string().required("Super Batch is required"),
      qty: Yup.number().required("Qty is required"),
    }),
    onSubmit: (values) => {
      const data = {
        sap: values.sap.value,
        batch: values.batch,
        superBatch: values.superBatch,
        qty: values.qty,
        shipmentUuid: uuid,
      };

      if (!values.uuid) {
        _csmshipment
          .CreateShipmentPlan(data)
          .then((response) => {
            setToastInfo({
              message: "shipment plan successfully created",
              background: "success",
            });
            setIsShowToast(true);
            setRefresh((prev) => !prev);
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "shipment plan failed created",
              background: "danger",
            });
            setIsShowToast(true);
          });
      } else {
        _csmshipment
          .UpdateShipmentPlan(values.uuid, data)
          .then((response) => {
            setToastInfo({
              message: "shipment successfully updated",
              background: "success",
            });
            setIsShowToast(true);
            setRefresh((prev) => !prev);
          })
          .catch((err) => {
            setToastInfo({
              message:
                err.response.status === 403
                  ? err.response?.data?.message
                  : "shipment failed updated",
              background: "danger",
            });
            setIsShowToast(true);
          });
      }
    },
  });

  const tableHeader = [
    {
      name: "Item",
    },
    {
      name: "Batch",
    },
    {
      name: "Super Batch",
    },
    {
      name: "Plan Qty",
    },
    {
      name: "Plan Kg",
    },
    {
      name: "Shipment Qty",
    },
    {
      name: "Shipment Kg",
    },
    {
      name: "Percentage",
    },
    {
      name: "Action",
    },
  ];

  const tableBody = [
    {
      name: "sap",
    },
    {
      name: "batch",
    },
    {
      name: "superBatch",
    },
    {
      name: "planQty",
    },
    {
      name: "planQty",
      view: (data) => parseFloat(data.planQty * data.kgs).toFixed(2),
    },
    {
      name: "shipmentQty",
    },
    {
      name: "planQty",
      view: (data) => parseFloat(data.shipmentQty * data.kgs).toFixed(2),
    },
    {
      name: "percentage",
      view: (data) => parseFloat(data.percentage).toFixed(2) + " %",
    },
    {
      view: (data) => {
        return (
          <>
            <button
              className="btn btn-primary py-0 px-1 me-2"
              onClick={() => {
                formik.setValues({
                  ...data,
                  sap: { value: data.sap, label: data.sap },
                  qty: data.planQty,
                });
                createModal.toggleModal();
              }}
            >
              <i className="bi bi-pencil-square"></i>
            </button>
            <button
              className="btn btn-danger py-0 px-1 me-2"
              onClick={() => {
                formik.setValues({
                  ...data,
                  sap: { value: data.sap, label: data.sap },
                  qty: data.planQty,
                });
                confirmationModal.toggleModal();
              }}
            >
              <i className="bi bi-trash3"></i>
            </button>
          </>
        );
      },
    },
  ];

  const handleDelete = () => {
    _csmshipment
      .DeleteShipmentPlan(formik.values.uuid)
      .then((response) => {
        setToastInfo({
          message: "shipment plan successfully created",
          background: "success",
        });
        setIsShowToast(true);
        setRefresh((prev) => !prev);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "shipment plan failed created",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <h5 className="card-title">Shipment Plan</h5>
          <div className="align-items-center d-flex justify-content-between">
            <div className="d-flex">
              <button
                className="btn btn-primary me-1"
                onClick={() => {
                  formik.resetForm();
                  createModal.toggleModal();
                }}
              >
                Add
              </button>
              <button
                className="btn btn-danger me-1"
                onClick={() => setPlan((prev) => !prev)}
              >
                X
              </button>
            </div>
          </div>
        </div>
        <DataTable
          api={_csmshipment.PagedSearchShipmentPlan}
          pageSize={50}
          tableHeader={tableHeader}
          tableBody={tableBody}
          dependencies={[lastDataModificationTimestamp, refresh]}
          params={{ keyword: "", shipmentUuid: uuid }}
        />
      </div>
      <ConfirmationModal
        isOpen={confirmationModal.modal}
        toggle={confirmationModal.toggleModal}
        onSubmit={handleDelete}
        message={"Are you sure delete this item?"}
      />
      <Modal show={createModal.modal} onHide={createModal.toggleModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Shipment Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <Input
              label="Item"
              type="select-api"
              name="sap"
              onChange={formik.handleChange}
              value={formik.values.sap}
              placeholder="Select Item"
              errorMessage={formik.errors?.sap}
              isError={formik.errors.sap && formik.touched.sap}
              api={_csmpacking.GetSAPList}
              handleSetOptions={(pt) => ({ value: pt.code, label: pt.code })}
            />
            <Input
              label="Batch"
              type="text"
              name="batch"
              onChange={formik.handleChange}
              value={formik.values.batch}
              placeholder="Input batch"
              errorMessage={formik.errors?.batch}
              isError={formik.errors.batch && formik.touched.batch}
            />
            <Input
              label="Super Batch"
              type="text"
              name="superBatch"
              onChange={formik.handleChange}
              value={formik.values.superBatch}
              placeholder="Input super batch"
              errorMessage={formik.errors?.superBatch}
              isError={formik.errors.superBatch && formik.touched.superBatch}
            />
            <Input
              label="Qty"
              type="number"
              name="qty"
              onChange={formik.handleChange}
              value={formik.values.qty}
              placeholder="Input qty"
              errorMessage={formik.errors?.qty}
              isError={formik.errors.qty && formik.touched.qty}
            />
            <div className="row mb-3">
              <div className=" d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    createModal.toggleModal();
                  }}
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="ms-2 btn btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}
