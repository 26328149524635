import React from "react";
import RetouchingDetail from "./RetouchingDetail";
import RetouchingByDate from "./RetouchingByDate";

export default function Index() {
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Retouching</h1>
      </div>
      <section className="section">
        <div className="row">
          <RetouchingDetail />
        </div>
        <RetouchingByDate />
      </section>
    </main>
  );
}
