import { _Delete, _Get, _Patch, _Post } from "./base";
import Cookies from "js-cookie";

const endpoint = "csm/cutting";

class CSMCutting {
  constructor() {}

  GetPackingUuid = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/packing`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  GetCSMCode = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/indo`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  CreateCutting = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}`,
      data,
      _options
    );
  };

  CreateCuttingDetail = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}/detail`,
      data,
      _options
    );
  };

  CreateCuttingDetailIndo = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}/detail/indo`,
      data,
      _options
    );
  };

  GroupByMonth = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/pt`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  DeleteCutting = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(`${process.env.REACT_APP_API_URL}/${endpoint}/${uuid}`, {
      ..._options,
    });
  };

  CuttingDetail = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/detail`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  CuttingDetailIndo = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/detail/indo`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  CuttingDetailGroupByCuttingDate = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/detail/group`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  CuttingDetailGroupByCuttingDateIndo = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/detail/group/indo`,
      {
        params: {
          ...params,
        },
        ..._options,
      }
    );
  };

  DeleteCuttingDetailIndo = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(
      `${process.env.REACT_APP_API_URL}/${endpoint}/detail/indo/${uuid}`,
      {
        ..._options,
      }
    );
  };

  DeleteCuttingDetailCSM = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(
      `${process.env.REACT_APP_API_URL}/${endpoint}/detail/csm/${uuid}`,
      {
        ..._options,
      }
    );
  };

  ExportCuttingPTIndo = (uuid, params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/report/indo/${uuid}`,
      {
        params: {
          ...params,
        },
        ..._options,
      }
    );
  };

  ExportCuttingPTCSM = (uuid, params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/report/csm/${uuid}`,
      {
        params: {
          ...params,
        },
        ..._options,
      }
    );
  };

  GetIndoCode = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(`${process.env.REACT_APP_API_URL}/${endpoint}/code`, {
      params: {
        ...params,
      },
      ..._options,
    });
  };

  CreateNaturalLoin = (data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Post(
      `${process.env.REACT_APP_API_URL}/${endpoint}/naturalloin`,
      data,
      _options
    );
  };

  ReadNaturalLoin = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/naturalloin/detail/${uuid}`,
      {
        ..._options,
      }
    );
  };

  UpdateNaturalLoin = (uuid, data) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Patch(
      `${process.env.REACT_APP_API_URL}/${endpoint}/naturalloin/detail/${uuid}`,
      data,
      {
        ..._options,
      }
    );
  };

  DeleteNaturalLoin = (uuid) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Delete(
      `${process.env.REACT_APP_API_URL}/${endpoint}/naturalloin/detail/${uuid}`,
      {
        ..._options,
      }
    );
  };

  NaturalLoinListDate = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/naturalloin/date`,
      {
        params: {
          ...params,
        },
        ..._options,
      }
    );
  };

  NaturalLoinListDetail = (params) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${process.env.REACT_APP_API_URL}/${endpoint}/naturalloin/${params.cuttingDate}/${params.cuttingUuid}`,
      {
        params: {
          ...params,
        },
        ..._options,
      }
    );
  };

  ExportNaturalLoint = (uuid, cuttingDate) => {
    let accessToken = Cookies.get("accessToken");
    let _options = {
      headers: {
        contentType: "application/json",
        authorization: `bearer ${accessToken}`,
      },
      withCredentials: true,
    };
    return _Get(
      `${
        process.env.REACT_APP_API_URL
      }/${endpoint}/naturalloin/report/${uuid}/${
        cuttingDate ? cuttingDate : ""
      }`,
      {
        ..._options,
      }
    );
  };
}

export default CSMCutting;
