import React from "react";
import { DataTable } from "../../Components";
import { Modal } from "react-bootstrap";
import CSMShipment from "../../API/CSMShipment";

export default function SummarizeModal({ show, toggle, shipmentUuid }) {
  const _csmshipment = new CSMShipment();
  const tableHeadersCSM = [
    {
      name: "SAP",
    },
    {
      name: "Description",
    },
    {
      name: "Batch",
    },
    {
      name: "Super Batch",
    },
    {
      name: "Total Case",
    },
    {
      name: "Total Weight",
    },
  ];

  const tableBodyCSM = [
    {
      name: "sap",
    },
    {
      name: "description",
    },
    {
      name: "batch",
    },
    {
      name: "superBatch",
    },
    {
      name: "totalCount",
    },
    {
      name: "totalWeight",
      view: (data) => parseFloat(data.totalWeight).toFixed(2),
    },
  ];
  return (
    <Modal show={show} onHide={toggle} centered size="xl">
      <Modal.Header closeButton>
        <Modal.Title>Summarize</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DataTable
          api={_csmshipment.ShipmentDetailSummarize}
          params={{
            shipmentUuid,
          }}
          tableHeader={tableHeadersCSM}
          tableBody={tableBodyCSM}
          size={20}
        />
      </Modal.Body>
    </Modal>
  );
}
