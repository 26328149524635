import React, { useEffect, useRef, useState } from "react";
import { ReadReceivingByDate, DeleteReceiving } from "../../API";
import { handleSizeReceiving } from "../../Utility";
import { useApplicationStoreContext } from "../../Hook/UserHook";
import { ModalPopUp, Loading } from "../../Components";
import GGForm from "./GGForm";
import LoinForm from "./LoinForm";

export default function DetailTable({ vendor, dailyDate, setDailyDate }) {
  const { setToastInfo, setIsShowToast } = useApplicationStoreContext();
  const [isShowModal, setIsShowModal] = useState();
  const [data, setData] = useState();
  const [selectedData, setSeledtedData] = useState();
  const [action, setAction] = useState();
  const [lastDataModificationTimestamp, setLastDataModificationTimestamp] =
    useState();
  const ref = useRef();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    ReadReceivingByDate(vendor.uuid, dailyDate).then((response) => {
      setData(response.data);
      setIsLoading(false);
    });
  }, [dailyDate, lastDataModificationTimestamp]);

  const deleteData = () => {
    DeleteReceiving(selectedData.uuid)
      .then(() => {
        setToastInfo({
          message: "Receiving successfully deleted",
          background: "success",
        });
        setIsShowToast(true);
        setIsShowModal(false);
        const undeletedData = data.filter(
          (item) => item.uuid !== selectedData.uuid
        );
        setData(undeletedData);
      })
      .catch((err) => {
        setToastInfo({
          message:
            err.response.status === 403
              ? err.response?.data?.message
              : "Receiving failed deleted",
          background: "danger",
        });
        setIsShowToast(true);
      });
  };

  const deleteModal = () => {
    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Delete Receiving</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">{`Are you sure delete ${selectedData.fishCode} ?`}</div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setIsShowModal(false)}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-danger"
            onClick={() => deleteData()}
          >
            Delete
          </button>
        </div>
      </div>
    );
  };

  const updateModal = () => {
    const onUpdate = () => {
      setLastDataModificationTimestamp(new Date().getTime());
    };

    return (
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Update Receiving</h5>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setIsShowModal(false)}
          />
        </div>
        <div className="modal-body">
          {selectedData && selectedData.rawMaterialType === "GG" && (
            <GGForm
              uuid={selectedData.uuid}
              setIsOpen={setIsShowModal}
              onUpdate={onUpdate}
            />
          )}
          {selectedData &&
            (selectedData.rawMaterialType === "CL" ||
              selectedData.rawMaterialType === "DL") && (
              <LoinForm
                uuid={selectedData.uuid}
                setIsOpen={setIsShowModal}
                onUpdate={onUpdate}
              />
            )}
        </div>
      </div>
    );
  };

  const component = () => {
    if (action === "delete") {
      return deleteModal();
    } else {
      return updateModal();
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="col-lg-12" ref={ref}>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">
              {vendor.name} {dailyDate}
            </h5>
            <div className="row align-items-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault();
                  setDailyDate();
                }}
              >
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Code</th>
                <th scope="col">Type</th>
                <th scope="col">Pcs</th>
                <th scope="col">Size</th>
                <th scope="col">Grade</th>
                <th scope="col">Weight</th>
                <th scope="col">Owner</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <th>{item.fishCode}</th>
                      <td style={{ textTransform: "uppercase" }}>
                        {item.rawMaterialType}
                      </td>
                      <td>{item.pcs}</td>
                      <td>{handleSizeReceiving(item.size)}</td>
                      <td>{item.grade}</td>
                      <td>{item.weight}</td>
                      <td>{item.owner}</td>
                      <td>
                        <button
                          className="btn btn-primary py-0 px-1 me-2"
                          onClick={(e) => {
                            e.preventDefault();
                            setSeledtedData(item);
                            setAction("edit");
                            setIsShowModal(true);
                          }}
                        >
                          <i className="bi bi-pencil-square"></i>
                        </button>
                        <button
                          className="btn btn-danger py-0 px-1"
                          onClick={(e) => {
                            e.preventDefault();
                            setSeledtedData(item);
                            setAction("delete");
                            setIsShowModal(true);
                          }}
                          data-bs-toggle="modal"
                          data-bs-target="#verticalycentered"
                        >
                          <i className="bi bi-trash3"></i>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      <ModalPopUp
        component={component}
        isOpen={isShowModal}
        setIsOpen={setIsShowModal}
      />
    </div>
  );
}
